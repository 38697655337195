let base_url_dev = "https://roses-api-e350fa33a4a8.herokuapp.com";
let base_url_stg = "https://roses-stg-9d734169df65.herokuapp.com";
let base_url_prod = "https://roses-backend-hsk6gjckza-ue.a.run.app";
let base_url_images =
  "https://us-east1-roses--development.cloudfunctions.net/upload-files";


const currentURL = base_url_dev;

const servicesAPI = {
  whatsappCode: `${currentURL}/wpp`,
  smsCode: `${currentURL}/sms`,
  membership: `${currentURL}/membership-applications`,
  user: `${currentURL}/user`,
  chat: `${currentURL}/chat`,
  auth: `${currentURL}/auth`,
  contact: `${currentURL}/contact`,
  mvpTests: `${currentURL}/mvp-tests`,
  profileDetails: `${currentURL}/profile-details`,
  ipInfo: `${currentURL}/ipinfo`,
  image: `${base_url_images}`,
	creator_applications:`${currentURL}/creator-applications`,
	coins:`${currentURL}/coins`,
  autoRecharge:`${currentURL}/auto-recharge`,
  extract:`${currentURL}/extract`,
} as const;

export type ServiceType = keyof typeof servicesAPI;

export const servicesApi = <T extends ServiceType>(
  service: T
): (typeof servicesAPI)[T] => {
  return servicesAPI[service];
};
