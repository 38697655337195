import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ButtonNavigation from "../../components/Buttons/ButtonNavigation";
import InputText from "../../components/Inputs/InputText";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import { useWebSocket } from "../../contexts/webSocketContext";
import { useNavigate } from "react-router-dom";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import getListUserChats from "../../api/chat/getListUserChats";
import { useUserInfo } from "../../hooks/userInfo";
import { Chat } from "../../types/chatInfo";
import profileAvatar from "../../assets/icons/profile-icon-gray.svg";
import { useChat } from "../../contexts/openedChatContext";

const ChatListPage: React.FC = () => {
  const { getChats } = useWebSocket();
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [chats, setChats] = useState<Chat[]>([]);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<{
    [chatId: string]: number;
  }>({});
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();
  const { addChatListener, removeListener } = useWebSocket();
  const { setTertiaryScreen, setSecondaryScreen, setPrincipalScreen } =
    useDeskNavigation();
  const { setActiveChatId } = useChat();

  useEffect(() => {
    const handleNewMessage = (newMessage: any) => {
			console.log(newMessage.eventType)
      if (newMessage.eventType === "NEW_MESSAGE") {
        const { chatId, isRead } = newMessage.payload;
        setUnreadMessagesCount((prev) => ({
          ...prev,
          [chatId]: !isRead ? (prev[chatId] || 0) + 1 : prev[chatId],
        }));
      } else {
        return;
      }
    };

    addChatListener(handleNewMessage);

    return () => {
      removeListener("events", handleNewMessage);
    };
  }, [addChatListener, removeListener]);

  const handleChat = (chatId: string, userId: string) => {
    setActiveChatId(chatId);
    setTimeout(() => {
      setUnreadMessagesCount((prev) => ({
        ...prev,
        [chatId]: 0,
      }));

      setSecondaryScreen("/personal-chat", { chatId, userId });
    }, 500);
  };

  const messageInChatRequest = async () => {
    const response = await getListUserChats(userInfo.access_token);

    if (response.status === 200) {
    } else {
      navigate("/app-login");
    }
  };

  const getListChats = useCallback(() => {
    getChats({}, (res) => {
      setChats(res);
    });
  }, [getChats]);

  useEffect(() => {
    messageInChatRequest();
    getListChats();
  }, [getListChats]);

  const messagePreview = (message: string) => {
    return message?.length > 50 ? `${message.substring(0, 40)}...` : message;
  };

  const backPageHandler = () => {
    setPrincipalScreen("");
    setTertiaryScreen("");
    setSecondaryScreen("");
    setActiveChatId(null);
  };

  return (
    <div className="chat-list margin-hor-24">
      <div className="top-container">
        <PageTitle
          title={"Messages"}
          subtitle={"Conversation"}
          onBackClick={backPageHandler}
        />
        <InputText
          value={"Search"}
          placeholder="Search"
          onChangeInput={() => {}}
          searchInput
          inputType="text"
        />
        <ButtonNavigation
          setActiveSection={setActiveSection}
          activeSection={activeSection}
          buttonCount={2}
          buttonLabels={["Messages", "Notifications"]}
          className="margin-vert-24"
        />
      </div>

      {activeSection === "button1" && (
        <>
          {chats.map((chat) => (
            <ListItemSecondary
              key={chat.chatId}
              clickAction={() =>
                handleChat(chat.chatId, chat.chatParticipants[0]?.user.userId)
              }
              showLabel
              labelContent={chat.chatParticipants[0]?.user.displayname}
              showIconLeft
              iconLeftContent={
                chat.chatParticipants[0]?.user.profile?.photos[0]?.url
                  ? chat.chatParticipants[0]?.user.profile?.photos[0]?.url
                  : profileAvatar
              }
              showIconBorderLeft
              showDescription
              descriptionContent={
                messagePreview(chat.messages[0]?.content) || ""
              }
              showIconRight
              iconRightContent={chevronRightIcon}
              showSeparator
              nodeSectionEndTextContainer={
                <div
                  className="notification-count"
                  style={{
                    display:
                      chat._count.messages +
                        (unreadMessagesCount[chat.chatId] || 0) ===
                      0
                        ? "none"
                        : "flex",
                  }}
                >
                  <p>
                    {chat._count.messages +
                      (unreadMessagesCount[chat.chatId] || 0)}
                  </p>
                </div>
              }
            />
          ))}
        </>
      )}

      {activeSection === "button2" && <></>}
    </div>
  );
};

export default ChatListPage;
