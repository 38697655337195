import { useRef, useState } from "react";
import Header from "../../components/Headers/Header";
import "./styles.scss";
import Button from "../../components/Buttons/Button";
import DocumentPlaceholder from "../../components/DocumentPlaceholder";

import PageTitle from "../../components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useFiles } from "../../contexts/documentContext";

const UploadDocumentPage: React.FC = () => {
  const [documentFileFront, setDocumentFileFront] = useState<File | null>(null);
  const [documentFileBack, setDocumentFileBack] = useState<File | null>(null);
  const { setPrincipalScreen } = useDeskNavigation();
  const { setFiles } = useFiles();

  const fileInputRefFront = useRef<HTMLInputElement>(null);
  const fileInputRefBack = useRef<HTMLInputElement>(null);

  const documentFromGallery = () => {
    if (!documentFileFront) {
      fileInputRefFront.current?.click();
    } else if (!documentFileBack) {
      fileInputRefBack.current?.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!documentFileFront) {
        setDocumentFileFront(file);
      } else if (!documentFileBack) {
        setDocumentFileBack(file);
      }
    }
  };

  const captureDocument = () => {
    if (verifyDocFiles()) {
      // setFiles([documentFileFront!, documentFileBack!]);
      setPrincipalScreen("/recovery-email");
    }
  };

  const verifyDocFiles = () => {
    return documentFileFront && documentFileBack;
  };

  return (
    <div className="upload-document">
      <PageTitle
        title="Settings"
        subtitle="Configuration"
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/create-legal-name")}
      />
      <main className="margin-hor-24">
        <Header
          title="Upload your document"
          subTitle={`Please upload a document for identity verification. Ensure
					 it's legible and up-to-date for a smooth verification process.`}
          marginTop={0}
        />
        <DocumentPlaceholder
          docBackCallback={setDocumentFileBack}
          docFrontCallback={setDocumentFileFront}
          documentFileFront={documentFileFront}
          documentFileBack={documentFileBack}
        />
        <input
          ref={fileInputRefFront}
          onChange={handleFileChange}
          type="file"
          name="document"
          style={{ display: "none" }}
          accept=".jpg,.jpeg,.png"
        />
        <input
          ref={fileInputRefBack}
          onChange={handleFileChange}
          type="file"
          name="document"
          style={{ display: "none" }}
          accept=".jpg,.jpeg,.png"
        />
        {!verifyDocFiles() ? (
          <>
            <Button buttonStyle="quaternary" onClick={documentFromGallery}>
              Photo Album
            </Button>
            <Button buttonStyle="secondary" onClick={captureDocument}>
              Capture Document
            </Button>
          </>
        ) : (
          <Button buttonStyle="primary" onClick={captureDocument}>
            Continue
          </Button>
        )}
      </main>
    </div>
  );
};

export default UploadDocumentPage;
