import React, { useRef, useState, useEffect } from "react";
import "./styles.scss";
import CloseIconWhite from "../../../assets/icons/closeIcons/close-icon-white.svg";

import RosesLogoGray from "../../../assets/icons/logos/logo-rose-icon-gray.svg";
import PackIconGray from "../../../assets/icons/pack-icon.svg";
import MicIconGray from "../../../assets/icons/keyBoardIcons/mic-icon-gray.svg";
import VideoCameraIconGray from "../../../assets/icons/camera-gray.svg";
import CameraIconGray from "../../../assets/icons/camera-icon-gray.svg";
import MultiPhotoGray from "../../../assets/icons/multi-photo-gray.svg";
import AddMediaModal from "../../../pages/AddMediaPage";
import { useModal } from "../../../hooks/useModal";
import { useDeskNavigation } from "../../../hooks/useDeskNavigation";
import { useLoginSignUpNavigation } from "../../../hooks/useLoginSignUpNavigation";
import { useWindowWidthSize } from "../../../hooks/useWindowWidthSize";

interface PinkButtonProps {
  sendRoses: () => void;
  sendPack: (files: File[]) => void;
  // sendAudio: () => void;
  // openCamera: (files: File[]) => void;
  sendMedia: (files: File[]) => void;
  hasButtonExpansion?: boolean;
  isButtonsDisabled?: boolean;
  className?: string;
}

const PinkButton: React.FC<PinkButtonProps> = (props) => {
  const {
    sendRoses,
    sendPack,
    // sendAudio,

    sendMedia,
    hasButtonExpansion = true,
    isButtonsDisabled,
    className,
  } = props;

  const [isListButtonShow, setIsListButtonShow] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [fileType, setFileType] = useState("");

  const fileInputRefFront = useRef<HTMLInputElement>(null);
  const { setOpenUseModal, setModalContent } = useModal();
  const {
    setSecondaryScreen,
    setTertiaryScreen,
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    mainScreen,
    setMainScreen,
    params,
  } = useDeskNavigation();
  const { setComponent } = useLoginSignUpNavigation();
  const { windowSize } = useWindowWidthSize();

  const buttonsConfig = [
    {
      id: 0,
      supTextButton: "Roses",
      icon: RosesLogoGray,
      alt: "roses logo gray",
      click: () => sendRoses(),
    },
    {
      id: 1,
      supTextButton: "Pack",
      icon: PackIconGray,
      alt: "pack icon gray",
      click: () => {
        setFileType("pack");
        fileHandler();
      },
    },
    // {
    //   id: 2,
    //   supTextButton: "Audio",
    //   icon: MicIconGray,
    //   alt: "mic icon gray",
    //   click: () => sendAudio(),
    // },
    {
      id: 3,
      supTextButton: "Media",
      icon: MultiPhotoGray,
      alt: "camera icon gray",
      click: () => {
        setFileType("media");
        fileHandler();
      },
    },
    // {
    //   id: 4,
    //   supTextButton: "Camera",
    //   icon: CameraIconGray,
    //   alt: "photo icon gray",
    //   click: () => {
    //     setSecondaryScreen("");
    //     setTertiaryScreen("");
    //     setMainScreen("/add-media-page", { fullscreen: "true" });
    //     setPrincipalScreen(windowSize > 390 ? "/share-content" : "");
    //   },
    // },
  ];


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) {
      const filesArray = Array.from(fileList);

      switch (fileType) {
        case "media":
          sendMedia(filesArray);
          break;
        case "pack":
          sendPack(filesArray);
          break;
      }

      setIsListButtonShow(false);
      event.target.value = "";
    }
  };

  const fileHandler = () => {
    setTimeout(() => {
      fileInputRefFront.current?.click();
    }, 0);
  };

  useEffect(() => {
    if (isListButtonShow) {
      setIsHidden(false);
    }
  }, [isListButtonShow]);

  const handleAnimationEnd = (e: React.AnimationEvent<HTMLDivElement>) => {
    if (e.animationName === "slide-down" && !isListButtonShow) {
      setIsHidden(true);
    }
  };

  return (
    <div className={`pink-button-component ${className}`}>
      <input
        type="file"
        name="upload-photo-front"
        ref={fileInputRefFront}
        accept={"video/*, .jpg,.jpeg,.png"}
        multiple={fileType === "pack"}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      {!isHidden && hasButtonExpansion && (
        <div
          className={`action-pills ${
            isListButtonShow ? "show-buttons" : "hide-buttons"
          }`}
          onAnimationEnd={handleAnimationEnd}
        >
          {buttonsConfig.map((button) => (
            <div
              key={button.id}
              className={`action-buttons-wrapper ${button.supTextButton.toLowerCase()}-button ${
                isListButtonShow ? "show-buttons" : "hide-buttons"
              }`}
            >
              <button
                disabled={isButtonsDisabled}
                onClick={button.click}
                className="action-button_button"
              >
                <p className="no-margin">{button.supTextButton}</p>
                <img
                  src={button.icon}
                  alt={button.alt}
                  className="action-button_icon-button"
                />
              </button>
            </div>
          ))}
        </div>
      )}
      <button
        disabled={isButtonsDisabled}
        onClick={() => setIsListButtonShow(!isListButtonShow)}
        className="pink-button"
      >
        <img
          src={CloseIconWhite}
          alt="plus icon white"
          className={`${isListButtonShow ? "rotate-image" : ""}`}
        />
      </button>
    </div>
  );
};

export default PinkButton;
