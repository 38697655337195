let deferredPrompt: any;

export const setupPWAInstall = () => {
  window.addEventListener("beforeinstallprompt", (e) => {
    console.log("event beforeinstallprompt");
    e.preventDefault();
    deferredPrompt = e;

    const installButton = document.getElementById("installButton");
    if (installButton) {
      installButton.style.display = "block";
    }
  });
};

export const handleInstallClick = () => {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === "accepted") {
        console.log("accepted");
      } else {
        console.log("decline");
      }
      deferredPrompt = null;
    });
  } else {
    console.log("Prompt de instalação não disponível.");
  }
};
