import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import getUsersListAll from "../../../api/getUsersListAll";
import { ICatalogResponse } from "../../../types/catalog";

import photoDefault from "../../../assets/images/roses-photo-default.png";
import { useUserInfo } from "../../../hooks/userInfo";

interface CardsCatalogProps {
  catalogHandlerCallback?: (a: any) => void;
  className?: string;
  filterType?: "all" | "vip" | "non-vip";
}

const CardsCatalog: React.FC<CardsCatalogProps> = (props) => {
  const { catalogHandlerCallback, className, filterType = "all" } = props;
  const { userInfo } = useUserInfo();
  const [catalogData, setCatalogData] = useState<ICatalogResponse[] | []>([]);
  const [classWidth, setClassWidth] = useState("mobile-width");
  const [isHovered, setIsHovered] = useState(false);
  const [counters, setCounters] = useState<string[]>([]); //MOCK

  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const generateRandomNumber = (index: number) => {
      const randomNum =
        Math.floor(Math.random() * (20000 - 200 + 1)) + 200 + index * 100;
      return randomNum;
    };

    const formatCounter = (num: number) => {
      if (num >= 1000) {
        return (num / 1000).toFixed(1) + "k";
      }
      return num.toString();
    };

    const randomCounters = catalogData.map((_, index) => {
      const randomCounter = generateRandomNumber(index);
      return formatCounter(randomCounter);
    });

    setCounters(randomCounters);
  }, [catalogData]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === divRef.current) {
          const { width } = entry.contentRect;

          let size = Math.floor(width + 48);

          if (size < 560) {
            setClassWidth("mobile-width");
          } else if (size >= 560 && size < 760) {
            setClassWidth("mobile-width-plus");
          } else if (size >= 760 && size < 1024) {
            setClassWidth("super-mobile-width");
          } else if (size >= 1024 && size < 1140) {
            setClassWidth("tablet-width");
          } else if (size >= 1140 && size < 1600) {
            setClassWidth("widescreen-width");
          } else {
            setClassWidth("hyper-widescreen-width");
          }
        }
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const node = divRef.current;
    if (node) {
      node.addEventListener("mouseenter", handleMouseEnter);
      node.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (node) {
        node.removeEventListener("mouseenter", handleMouseEnter);
        node.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  useEffect(() => {
    const getCatalogData = async (userToken: string) => {
      let response = await getUsersListAll(userToken);

      switch (response?.status) {
        case 200:
          setCatalogData(response.res);
          break;
        default:
          break;
      }
    };

    getCatalogData(userInfo.access_token);
  }, []);

  const filteredCatalogData = catalogData.filter((profileCard) => {
    if (filterType === "vip") {
      return profileCard.isVip;
    } else if (filterType === "non-vip") {
      return !profileCard.isVip;
    }
    return true;
  });

  const sortedCatalogData = filteredCatalogData.sort((a, b) =>
    a.isVip === b.isVip ? 0 : a.isVip ? -1 : 1
  );

  return (
    <div id="catalog" ref={divRef} className={`${className} ${classWidth}`}>
      {sortedCatalogData?.map((profileCard, index) => (
        <div
          onClick={() => {
            if (catalogHandlerCallback) {
              catalogHandlerCallback(profileCard);
            }
          }}
          className={`profile-card ${
            !isHovered ? "remove-linear-gradient" : ""
          }`}
          key={index}
        >
          {profileCard?.isVip && (
            <div className="vip-tag">
              <div className="background " />
              <p className="small-p no-margin">VIP</p>
            </div>
          )}
          <img
            src={profileCard?.profile.photos[0]?.url || photoDefault}
            alt="catalog gallery"
            className="catalog-image"
          />

          <div className="profile-title">
            <div className="user-info">
              <div className="user-title">
                <p className="profile-info small no-margin">
                  {profileCard?.displayname},
                  <span>{profileCard?.profile.age}</span>{" "}
                </p>
                {true && (
                  <span className="icon icon-verified-1 icon-sm icon-blue"></span>
                )}
              </div>
              {/* <Rating
                rating={
                  profileCard.profile.rating || Math.floor(Math.random() * 6)
                }
                isVip={profileCard?.isVip}
              /> */}
              <div className="followers-counter">
                <span
                  className={`icon icon-heart-solid icon-sm ${
                    !profileCard?.isVip ? "icon-gray" : ""
                  }`}
                ></span>
                <p className="counter">{counters[index]}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardsCatalog;
