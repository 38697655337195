import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import accountIconGray from "../../assets/icons/gear-icon-gray.svg";
import profileIconGray from "../../assets/icons/profile-icon-gray.svg";
import notificationsIconGray from "../../assets/icons/notification-icon-gray.svg";
import shieldIcon from "../../assets/icons/shieldIcons/personal-icon-gray.svg";
import myOffersIconGray from "../../assets/icons/offers-icon-gray.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import Button from "../../components/Buttons/Button";
import { useUserInfo } from "../../hooks/userInfo";
import { useEffect } from "react";
import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";
import wallet from "../../assets/icons/wallet-icon-gray.svg";
import startGray from "../../assets/icons/star-icon-gray.svg";
import linkIcon from "../../assets/icons/link-icon-gray.svg";
import TextActionCard from "../../components/Cards/TextActionCard";

const UserSettingsPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo, setUserInfo } = useUserInfo();

  useEffect(() => {
    const creatorApplyStatus = async () => {
      const responseStatus = await getCreatorStatus(userInfo.access_token);

      if (responseStatus?.status === 200) {
        setUserInfo("referral_code", responseStatus?.res.referralCode);
        setUserInfo("creator_application_status", responseStatus?.res.status);
        setUserInfo(
          "creator_application_id",
          responseStatus?.res.creatorApplicationId
        );
      } else if (responseStatus?.status === 404) {
        setUserInfo("creator_application_status", "unsolicited");
      }
    };

    creatorApplyStatus();
  }, []);

  const becameACreatorHandler = () => {
    if (userInfo.creator_application_status === "PENDING") {
      setPrincipalScreen("/wait-list-application");
    } else {
      setPrincipalScreen("/create-legal-name");
    }
  };

  const userSettingsItems = [
    {
      labelContent: "Account",
      iconLeftContent: accountIconGray,
      clickAction: () => setPrincipalScreen("/user-settings-account"),
      isActive: true,
    },
    {
      labelContent: "Profile",
      iconLeftContent: profileIconGray,
      clickAction: () => setPrincipalScreen("/user-settings-profile"),
      isActive: true,
    },
    {
      labelContent: "My Wallet",
      iconLeftContent: wallet,
      clickAction: () => setPrincipalScreen("/user-settings-wallet"),
      isActive: true,
    },
    {
      labelContent: "Notifications",
      iconLeftContent: notificationsIconGray,
      clickAction: () => setPrincipalScreen(""),
      isActive: true,
    },
    {
      labelContent: "Subscription",
      iconLeftContent: startGray,
      clickAction: () => setPrincipalScreen(""),
      isActive: true,
    },
    {
      labelContent: "Personal Safety",
      iconLeftContent: shieldIcon,
      clickAction: () => setPrincipalScreen(""),
      isActive: true,
    },
    {
      labelContent: "Referrals",
      iconLeftContent: linkIcon,
      clickAction: () => setPrincipalScreen(""),
      isActive: userInfo.creator_application_status !== "unsolicited",
    },
    {
      labelContent: "My Offers",
      iconLeftContent: myOffersIconGray,
      clickAction: () => setPrincipalScreen(""),
      isActive: true,
    },
  ];

  return (
    <div className="user-settings">
      <PageTitle
        title={"Settings"}
        subtitle={"Configuration"}
        onBackClick={() => setPrincipalScreen("")}
        className="margin-hor-24"
      />

      <div className="list-container-settings margin-hor-24">
        {userSettingsItems
          .filter((item) => item.isActive)
          .map((item, index) => (
            <ListItemSecondary
              key={index}
              showLabel={true}
              labelContent={item.labelContent}
              showIconLeft={true}
              iconLeftContent={item.iconLeftContent}
              showIconBorderLeft={true}
              showIconRight={true}
              iconRightContent={chevronRightIcon}
              showSeparator={true}
              clickAction={item.clickAction}
              className=""
            />
          ))}
      </div>
      {userInfo.creator_application_status === "unsolicited" && (
        <TextActionCard
          className="margin-24"
          buttonClick={becameACreatorHandler}
          principalText="Start Earning (Monetize Content)"
          secondaryText="Apply for a creator’s account and monetize your exclusive content today."
          textButton="Become a Creator"
        />
      )}
    </div>
  );
};

export default UserSettingsPage;
