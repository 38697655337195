import { useState } from "react";
import "./styles.scss";
import LockIcon from "../../assets/icons/lock-icon-gray.svg";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import PageTitle from "../../components/PageTitles";
import UpdatePassword from "../../api/auth/postPasswordUpdate";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { IToast } from "../../types/toast";
import Toast from "../../components/Toast";
import ChangePassword from "../../api/user/changePassword";
const UserSettingsChangePasswordPage: React.FC = () => {
  const [userCurrentPassword, setUserCurrentPassword] = useState("");
  const [userNewPassword, setUserNewPassword] = useState("");
  const [userNewPasswordConfirm, setUserNewPasswordConfirm] = useState("");
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [toastShow, setToastShow] = useState(false);

  const [newPasswordWarning, setNewPasswordWarning] = useState(false);
  const [confirmNewPasswordWarning, setConfirmNewPasswordWarning] =
    useState(false);
  const [currentPasswordWarning, setCurrentPasswordWarning] = useState(false);

  const { userInfo } = useUserInfo();
  const { setPrincipalScreen } = useDeskNavigation();
  //const { userEmail, setUserEmail } = useState();
  const resetPasswordSubmit = async () => {
    if (!userCurrentPassword) {
      setToastConfig({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "error",
      });

      setToastShow(true);
      setCurrentPasswordWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setCurrentPasswordWarning(false);
      }, 3000);
    }
    if (!userNewPassword) {
      setToastConfig({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "error",
      });
      setToastShow(true);
      setNewPasswordWarning(true);
      setTimeout(() => {
        setToastShow(false);
        setNewPasswordWarning(false);
      }, 3000);
    }
    if (!userNewPasswordConfirm) {
      setToastConfig({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "error",
      });

      setToastShow(true);
      setConfirmNewPasswordWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setConfirmNewPasswordWarning(false);
      }, 3000);
    }
    if (userNewPassword === userNewPasswordConfirm) {
      if (
        userNewPassword !== "" &&
        userNewPasswordConfirm !== "" &&
        userCurrentPassword
      ) {
        const update = await ChangePassword({
          oldPassword: userCurrentPassword,
          newPassword: userNewPassword,
        });

        if (update.res.message.statusCode === 200) {
          setToastConfig({
            description: "Success changing passwords",
            title: "Reset password",
            type: "success",
          });

          setToastShow(true);

          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 3000);
          console.log(update.res.message);
        } else if (update.res.message.statusCode === 404) {
          setToastConfig({
            description:
              "Server error changing password, please try again later",
            title: "Reset password",
            type: "error",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 3000);
          console.log(update.res.message);
        }
      }
    } else {
      setToastConfig({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "error",
      });

      setToastShow(true);
      setNewPasswordWarning(true);
      setConfirmNewPasswordWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setNewPasswordWarning(false);
        setConfirmNewPasswordWarning(false);
      }, 3000);
    }
  };

  const verifyPasswordsInputsEmpty = () => {};
  const verifyPasswordNew = () => {};
  const verifyPasswordEquality = () => {};
  const verifyPasswordslenght = () => {};
  const verifyCurrentPassword = () => {};

  const cancelSubmit = () => {
    setPrincipalScreen("/user-settings-account");
  };


  return (
    <div className="user-settings-change-password">
      <PageTitle
        title={"Password & security"}
        subtitle={"Account Security"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/user-settings-account")}
        className="margin-hor-24"
      />
      <div className="margin-hor-24">
        <SubText>Your password should be at least 8 characters long</SubText>
        <InputText
          value={userCurrentPassword}
          placeholder="Current Password"
          onChangeInput={(e) => setUserCurrentPassword(e)}
          inputType="password"
          isWarning={currentPasswordWarning}
        />
        <div className="separator" />
        <div className="password-container">
          <InputText
            value={userNewPassword}
            placeholder="New Password"
            onChangeInput={(e) => setUserNewPassword(e)}
            inputType="password"
            isWarning={newPasswordWarning}
          />
          <InputText
            value={userNewPasswordConfirm}
            placeholder="Confirm New Password"
            onChangeInput={(e) => setUserNewPasswordConfirm(e)}
            inputType="password"
            isWarning={confirmNewPasswordWarning}
          />
        </div>
        <SubText
          icon={LockIcon}
          altIcon="clock icon"
          className="margin-vert-16"
        >
          Never share your credentials with anyone.
        </SubText>{" "}
        <Button buttonStyle="primary" onClick={resetPasswordSubmit}>
          Reset Password
        </Button>
        <Button buttonStyle="quaternary" onClick={cancelSubmit}>
          Cancel
        </Button>
      </div>
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};
export default UserSettingsChangePasswordPage;
function postUserAuth(prepareData: any) {
  throw new Error("Function not implemented.");
}

