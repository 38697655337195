import { useState } from "react";
import Header from "../../components/Headers/Header";

import "./styles.scss";
import GalleryIcon from "../../assets/icons/keyBoardIcons/gallery-icon-gray.svg";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import PhotosPlaceholder from "../../components/PhotosPlaceholder";
import SubText from "../../components/Texts/SubText";

import Toast from "../../components/Toast";
import { useUserInfo } from "../../hooks/userInfo";
import PageTitle from "../../components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useFiles } from "../../contexts/documentContext";

const SendPhotoAlbumPage: React.FC = () => {
  const [instagramProfile, setInstagramProfile] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastErrorMessage, setToastErrorMessage] = useState("");
  const [photosAlbum, setPhotosAlbum] = useState<(File | null)[] | []>();
  const { userInfo, setUserInfo } = useUserInfo();

  const isCreator = userInfo.account_type === "creator";
  const { setPrincipalScreen } = useDeskNavigation();
  const { setFiles, files } = useFiles();

  const verifyImages = () => {
    if (photosAlbum) {
      const hasNullImage = photosAlbum.filter((img) => img === null);
      if (hasNullImage.length) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const sendPhotoSubmit = async () => {
    setLoading(true);
    setUserInfo("instagram", instagramProfile);

    if (verifyImages()) {
      const validPhotos =
        photosAlbum?.filter((photo): photo is File => photo !== null) || [];

      //@ts-ignore
      setFiles(validPhotos);
    }
    setPrincipalScreen("/terms-and-conditions");
    // const prepareData = {
    //   displayName: userInfo.display_name,
    //   phone: userInfo.user_phone.split("+")[1] as string,
    //   instagramUser: instagramProfile,
    //   type: userInfo.account_type === "creator" ? "CREATOR" : "CONSUMER",
    //   files: photosAlbum,
    // };

    // const response = await postNewMembership.postRegisterMembership(
    //   prepareData
    // );

    // switch (response.status) {
    //   case 201:
    //     setUserInfo("user_id", response.data.id);
    //     setUserInfo("instagram", instagramProfile);
    //     setComponent("/terms-and-conditions");
    //     break;

    //   default:
    //     setHasError(true);
    //     setToastErrorMessage("There was an error. Please try again.");

    //     setTimeout(() => {
    //       setHasError(false);
    //     }, 5000);

    //     break;
    // }

    // setLoading(false);
  };

  const onChangeInput = (instagram: string) => {
    let userInstagram;
    if (instagram[0] !== "@") {
      userInstagram = "@" + instagram;
    } else {
      userInstagram = instagram;
    }

    setInstagramProfile(userInstagram);
  };

  return (
    <div className="send-photo-album ">
      <PageTitle
        title={"Settings"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/recovery-email")}
      />

      <main className="margin-hor-24">
        <Header
          title={
            <>
              Add your <br />
              photos
            </>
          }
          subTitle={"Select 3 photos that clearly shows you."}
          marginTop={0}
        />
        <PhotosPlaceholder
          quantityFiles={3}
          photoAlbumCallback={(e) => setPhotosAlbum(e)}
        />

        <SubText className="margin-vert-8">
          Boost your approval chances by adding your Instagram profile. Your
          profile must be set to public (highly recommended).
        </SubText>

        <InputText
          value={instagramProfile}
          placeholder="@instagram (optional)"
          onChangeInput={(e) => onChangeInput(e)}
          textTransform="none"
          inputType="text"
        />

        <SubText
          icon={isCreator ? GalleryIcon : ""}
          altIcon="gallery photos icon"
          className="margin-vert-8"
        >
          We welcome sensual expression that's artistic and sophisticated.
          Please avoid explicit content to maintain the respectful and tasteful
          spirit of our community.
        </SubText>

        <Button
          buttonStyle="primary"
          onClick={sendPhotoSubmit}
          disabled={loading}
        >
          Submit application
        </Button>
      </main>

      <Toast
        type="error"
        title="Error"
        description={toastErrorMessage}
        isVisible={hasError}
        setIsVisible={setHasError}
      />
    </div>
  );
};

export default SendPhotoAlbumPage;
