import React, { useEffect, useState } from "react";
import "./styles.scss";
import Button from "../../components/Buttons/Button";
import ButtonNavigation from "../../components/Buttons/ButtonNavigation";
import InputText from "../../components/Inputs/InputText";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import PageTitle from "../../components/PageTitles";
import AutoRecharge from "../../components/Wallet/AutoRecharge";
import RosesDashboard from "../../components/Wallet/RosesDashboard";
import bankIcon from "../../assets/icons/bank.icon-gray.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import getUserSelfDetail from "../../api/getUserSelfDetail";
import postBuyRoses from "../../api/rosesTransactions/postBuyRoses";
import postWithdrawRoses from "../../api/rosesTransactions/postWithdrawRoses";
import getAutoRecharge from "../../api/rosesTransactions/autoRecharge/getAutoRecharge";
import getExtract from "../../api/extract/getExtract";
import dateShortFormated from "../../utils/dateShortFormat";
import invoicesMock from "../../mocks/Invoices";
import Toast from "../../components/Toast";
import { IToast } from "../../types/toast";

type IInvoice = {
  transactionType: string;
  time: string;
  value: number;
};

type ApiResponse = {
  type: string;
  createdAt: string;
  amount: number;
};

const UserSettingsWalletPage: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [cardDefault, setCardDefault] = useState("");
  const [userRosesAvailable, setUserRosesAvailable] = useState(0);
  const [invoices, setInvoices] = useState<IInvoice[]>();
  const [search, setSearch] = useState("");
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });

  const {
    setPrincipalScreen,
  } = useDeskNavigation();
  const { userInfo } = useUserInfo();

  useEffect(() => {
    getSelfDetail();
    handleGetExtract();
  }, []);

  const cards: {
    banner: string;
    cardType: "Debit" | "Credit";
    number: string;
    default: boolean;
  }[] = [
      {
        banner: "Visa",
        cardType: "Debit",
        number: "1234-5678-9012-3456",
        default: false,
      },
      {
        banner: "MasterCard",
        cardType: "Credit",
        number: "9876-5432-1098-7654",
        default: false,
      },
      {
        banner: "American Express",
        cardType: "Credit",
        number: "3714-496353-98431",
        default: true,
      },
      {
        banner: "Discover",
        cardType: "Debit",
        number: "6011-1111-1111-1117",
        default: false,
      },
    ];

  // const invoices = invoicesMock;

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  const applyRechargeRequest = async () => {
    const request = await getAutoRecharge(userInfo.access_token);
    console.log(request.res)
    return request;
  };

  const getSelfDetail = async () => {
    const request = await getUserSelfDetail(userInfo.access_token);
    if (request.status === 200) {
      setUserRosesAvailable(request.res.quantityRoses);
    }
  };

  const handleAddRoses = async () => {
    const packageId = "b6f8bf93-fe69-4327-bc31-d35d5a762a20";
    const request = await postBuyRoses(userInfo.access_token, packageId)

    if (request.status === 201) {
      const { res } = await getUserSelfDetail(userInfo.access_token);
      setUserRosesAvailable(res.quantityRoses);
      handleToast({ type: "success", title: "Buy Roses", description: "Congratulations" });
    } else {
      handleToast({ type: "error", title: "Buy Roses", description: "Congratulations" });
    }
  };

  const handleRedeemRoses = async () => {
    const request = await postWithdrawRoses(userInfo.access_token, userRosesAvailable);
    console.log(request.status)
    if (request.status === 201) {
      const { res } = await getUserSelfDetail(userInfo.access_token);
      setUserRosesAvailable(res.quatityRoses);
      handleToast({ type: "success", title: "Redeem Roses", description: "Congratulations" });
    } else if (request.status === 422) {
      handleToast({ type: "info", title: "Redeem Roses", description: "Not has roses to reddem" });
    }
    else {
      handleToast({ type: "error", title: "Redeem  Roses", description: "Error" });
    }
  }

  const handleGetExtract = async () => {
    const dateEnd = new Date().toISOString();
    const dateStart = new Date('2020-01-01').toISOString();
    const request = await getExtract(userInfo.access_token, userInfo.user_id, dateStart, dateEnd);

    if (request.status === 200) {
      const resMap = request.res.map((res: ApiResponse) => {
        return mapApiResponseToInvoice(res);
      });
      setInvoices(resMap);
    }
  }

  const mapApiResponseToInvoice = (response: ApiResponse): IInvoice => {
    return {
      transactionType: response.type,
      time: response.createdAt,
      value: response.amount,
    };
  }

  const handleToast = ({ type, title, description }: IToast) => {
    setToastShow(true);
    setToastConfig({
      type,
      title,
      description,
    });
    setTimeout(() => {
      setToastShow(false);
    }, 3000);
  }

  return (
    <div className="user-settings-wallet">
      <PageTitle
        title={"My Wallet"}
        subtitle={"Manage Payment"}
        onBackClick={() => setPrincipalScreen("/user-settings")}
        hasCloseIcon={false}
        className="padding-hor-24"
      />
      <ButtonNavigation
        setActiveSection={handleSectionChange}
        activeSection={activeSection}
        buttonCount={3}
        buttonLabels={["Overview", "Activity", "Payout"]}
        className="margin-bottom-24 padding-hor-24"
      />

      {activeSection === "button1" && (
        <section className="available-roses-recharge margin-hor-24">
          <RosesDashboard
            title={userRosesAvailable}
            showAddButton={true}
            showGoldRoses={true}
            onClick={handleAddRoses}
          />
          <AutoRecharge
            cards={cards}
            applyRechargeCallback={applyRechargeRequest}
          />
        </section>
      )}

      {activeSection === "button2" && (
        <section className="transactions-activity margin-hor-24">
          {/* <DashboardGrid /> */}
          <InputText
            value={search}
            placeholder="Search"
            onChangeInput={(text) => { setSearch(text) }}
            searchInput
            inputType="text"
          />
          <div className="list-container margin-top-16">
            {invoices && invoices.map((invoice, index) => {

              return <ListItemSecondary
                key={index}
                showLabel={true}
                labelContent={invoice.transactionType}
                showDescription={true}
                descriptionContent={dateShortFormated(invoice.time)}
                showValue={true}
                valueContent={invoice.transactionType === "WITHDRAW" ? invoice.value * -1 : invoice.value}
                showSeparator={true}
                className="list-container-list-item"
              />
            })}
          </div>
        </section>
      )}

      {activeSection === "button3" && (
        <section className="redeem-credits padding-hor-24">
          <RosesDashboard
            title={userRosesAvailable}
            showRedeemButton={true}
            onClick={handleRedeemRoses}
          />
          <p className="redeem-credits__header margin-top-16 margin-bottom-4">Bank account</p>
          <div className="list-container ">
            {cards.map((card) => (
              <ListItemSecondary
                key={card.number}
                showIconLeft={true}
                iconLeftContent={bankIcon}
                showIconBorderLeft={true}
                showLabel={true}
                labelContent={card.banner}
                showDescription={true}
                descriptionContent={card.number}
                showRadioRight={true}
                showSeparator={true}
                radioRightGroupName="card-default"
                radioRightValue={card.number}
                showValue={card.default}
                valueContent={"Default"}
                onChangeRightRadioCallback={setCardDefault}
                className={"list-container-list-item"}
              />
            ))}
          </div>
          <Button
            buttonStyle="tertiary"
            onClick={() => { }}
            children={"Connect Account"}
          />
        </section>
      )}

      <Toast
        type={toastConfig.type}
        title={toastConfig.title}
        description={toastConfig.description}
        isVisible={toastShow}
        setIsVisible={setToastShow}
      />
    </div>
  );
};

export default UserSettingsWalletPage;
