import React, { useContext, useEffect, useRef, useState } from "react";
import PageTitle from "../../components/PageTitles";
import "./styles.scss";
import ChatMessage from "../../components/Chat/ChatMessage";
import { useWebSocket } from "../../contexts/webSocketContext";
import { format } from "date-fns";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { ICatalogResponse } from "../../types/catalog";
import CreateChatContact from "../../api/postCreateChatContact";
import { useUserInfo } from "../../hooks/userInfo";
import ChatTextarea from "../../components/TextAreas/ChatTextArea";
import { ToggleChatOpenContext } from "../../contexts/toggleChatOpen";
import {
  Chat,
  IEventIsOnline,
  INewMessage,
  IReadMessage,
  Message,
} from "../../types/chatInfo";
import getPublicDetails from "../../api/publicDetails/getProfileDetails";
import PinkButton from "../../components/Buttons/PinkButton";
import { useModal } from "../../hooks/useModal";
import SendAudioChat from "../../components/SendAudioChat";
import postUploadMedia from "../../api/chat/postUploadMedia";
import postUnlockChatContent from "../../api/chat/postUnlockContent";
import Loading from "../../components/Loading";
import ChatLocked from "../../components/Chat/ChatLocked";
import PackMessageModal from "../../components/Chat/ChatModals/PackMessageModal";
import VideoMessageModal from "../../components/Chat/ChatModals/VideoMessageModal";
import ImageMessageModal from "../../components/Chat/ChatModals/ImageMessageModal";
import PreviewPackModal from "../../components/Chat/ChatModals/PreviewPackModal";
import PreviewMediaModal from "../../components/Chat/ChatModals/PreviewMediaModal";
import UnlockContentModal from "../../components/Chat/ChatModals/UnlockContentModal";
import SendRosesModal from "../../components/Chat/ChatModals/SendRosesModal";
import postSendRoses from "../../api/rosesTransactions/postSendRoses";
import getUserSelfDetail from "../../api/getUserSelfDetail";
import CardExplanatory from "../../components/Chat/CardExplanatoryChat";
import { useChat } from "../../contexts/openedChatContext";
import AddMediaModal from "../AddMediaPage";

const ChatPage: React.FC = () => {
  const {
    addChatListener,
    removeListener,
    sendMessage,
    getMessages,
    getChat,
    updateMessageStatus,
  } = useWebSocket();
  const {
    setSecondaryScreen,
    setTertiaryScreen,
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    mainScreen,
    setMainScreen,
    params,
  } = useDeskNavigation();
  const { setOpenUseModal, setModalContent } = useModal();

  const { chatId, userId, isLocked } = params;
  const { userInfo } = useUserInfo();
  const { setIsChatPageOpen } = useContext(ToggleChatOpenContext);
  const inputChatRef = useRef<HTMLInputElement>(null);
  const [chatLocked, setChatLocked] = useState<boolean>(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [chatInfo, setChatInfo] = useState<Chat>();
  const [hasAMomentToSee, setHasAMomentToSee] = useState(false);
  const [isContactOnline, setIsContactOnline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [producerDetails, setProducerDetails] = useState<ICatalogResponse>({
    displayname: "",
    isFavorite: false,
    isVip: false,
    profile: {
      age: 0,
      gender: "",
      height: 0,
      location: "",
      maritalStatus: "",
      occupation: "",
      photos: [],
      profileDetails: [],
      rating: 0,
      weight: 0,
    },
    userId: "",
  });
  const { setActiveChatId } = useChat();

  const [unreadMessageIds, setUnreadMessageIds] = useState<string[]>([]);
  const [userRosesAvailable, setUserRosesAvailable] = useState(0);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [readMessagesIds, setReadMessagesIds] = useState<string[]>([]);
  const messagesContainer = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (messagesContainer.current) {
      messagesContainer.current.scrollTop =
        messagesContainer.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const formattedTime = (date: Date) => format(date, "HH:mm");

  const backPageHandler = () => {
    //TODO
    //Precisa limpar o params
    // setSecondaryScreen("", { chatId: "0", userId:"0" });
    setSecondaryScreen("");
    setTertiaryScreen("");
    setActiveChatId(null);
    setIsChatPageOpen(false);
  };

  const openMediaLibrary = () => {
    setTertiaryScreen("/media-library");
  };

  useEffect(() => {
    const getChatInfo = () => {
      removeAllListeners();
      addChatListener(handleNewMessage);
      addChatListener(isContactOnlineListener);
      addChatListener(readMessageListener);
      handleMessages();
      getSelfDetail();
      getChat(chatId, setChatInfo);
    };

    const removeAllListeners = () => {
      removeListener("NEW_MESSAGE", handleNewMessage);
      removeListener("CONTACT_ONLINE", isContactOnlineListener);
      removeListener("READ_MESSAGE", readMessageListener);
    };

    const isChatLocked = isLocked === "false" ? false : true;
    setChatLocked(!isChatLocked);

    if (isChatLocked) {
      handleCreateChatContact();
    }

    producerDetail();
    getChatInfo();

    const scrollTimeout = setTimeout(scrollToBottom, 500);

    return () => {
      removeAllListeners();
      clearTimeout(scrollTimeout);
    };
  }, [chatId, isLocked, userId]);

  useEffect(() => {
    if (unreadMessageIds.length > 0) {
      const messagesIds = new Set(unreadMessageIds);

      markMessagesAsRead(Array.from(messagesIds));
    }
  }, [unreadMessageIds, updateMessageStatus]);

  const getSelfDetail = async () => {
    const request = await getUserSelfDetail(userInfo.access_token);

    switch (request.status) {
      case 200:
        setUserRosesAvailable(request.res.quantityRoses);
        break;
      default:
        break;
    }
  };

  const handleMessages = () => {
    const messageParams = {
      chatId,
      take: 15,
    };

    getMessages(messageParams, (messages: Message[]) => {
      const newMessages = messages.filter((msg) => msg.chatId === chatId);

      setMessages([...newMessages]);

      const interestUnreadMessagesIds = messages
        .filter((msg) => !msg.isRead && msg.userId === userId)
        .map((msg) => msg.messageId);

      if (interestUnreadMessagesIds.length > 0) {
        interestUnreadMessagesIds.forEach(addUnreadMessage);
      }
    });
    scrollToBottom();
  };

  const isContactOnlineListener = (event: IEventIsOnline) => {
    console.log("isContactOnlineListener", event);
    if (event.eventType === "USER_STATUS" && event.payload.userId === userId) {
      setIsContactOnline(event.payload.isOnline);
    }
  };

  const readMessageListener = (event: IReadMessage) => {
    console.log("readMessageListener", event);
    if (event.eventType === "MESSAGE_READ") {
      setReadMessagesIds((prev) => [...prev, event.payload.messageId]);
    }
  };

  const handleNewMessage = (event: INewMessage) => {
    console.log("handleNewMessage", event);
    if (event.eventType === "NEW_MESSAGE") {
      if (event.payload.chatId === chatId) {
        markMessagesAsRead([event.payload.messageId]);
      }

      setMessages((prevMessages) => {
        const isDuplicate = prevMessages.some(
          (msg) => msg.messageId === event.payload.messageId
        );
        if (!isDuplicate) {
          return [...prevMessages, event.payload];
        }
        return prevMessages;
      });

      setTimeout(scrollToBottom, 0);
    }
  };

  const markMessagesAsRead = (messageIds: string[]) => {
    updateMessageStatus(messageIds, (response) => {});
  };

  const addUnreadMessage = (newMessageId: string) => {
    setUnreadMessageIds((prevIds) => [...prevIds, newMessageId]);
  };

  const handleSendMessage = async (text: string) => {
    // setIsLoading(true);

    try {
      await new Promise<void>((resolve, reject) => {
        sendMessage({ chatId, content: text }, (message) => {
          setMessages((prevMessages) => [...prevMessages, message]);
          scrollToBottom();
          resolve();
        });
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      inputChatRef.current?.focus();
    }

    // setIsLoading(false);
  };

  const producerDetail = async () => {
    if (userInfo.access_token) {
      const response = await getPublicDetails(userInfo.access_token, userId);

      switch (response?.status) {
        case 200:
          setProducerDetails(response.res);

          break;
        default:
          // setPrincipalScreen("");
          break;
      }
    }
  };

  const handleCreateChatContact = async () => {
    setChatLocked(false);
    const chatInfo = await CreateChatContact().postCreateChatContact(
      userId,
      userInfo.access_token
    );

    setIsContactOnline(chatInfo?.res?.chatParticipants[0]?.user?.isOnline);
  };

  const prepareSendFiles = (files: File[]) => {
    return files.map((file) => {
      return new Promise<string | ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          if (reader.result) {
            resolve(reader.result as string);
          } else {
            reject(new Error("Failed to read file"));
          }
        };
        reader.onerror = () => reject(new Error("File reading error"));

        reader.readAsDataURL(file);
      });
    });
  };

  const unlockContent = async (messageId: string) => {
    setModalContent(
      <div className="loading-send-file">
        <Loading />
      </div>
    );

    const response = await postUnlockChatContent(
      userInfo.access_token,
      messageId
    );

    setOpenUseModal(false);
    setModalContent(null);
    handleMessages();
    scrollToBottom();
  };

  const uploadFileCallBack = async (
    file: File[],
    content: string,
    thumbnail?: string
  ) => {
    setModalContent(
      <div className="loading-send-file">
        <Loading />
      </div>
    );

    const prepareData = {
      chatId,
      content,
      price: 55,
      paidContent: true,
      files: file,
    };
    const response = await postUploadMedia(userInfo.access_token, prepareData);

    switch (response.status) {
      case 201:
        scrollToBottom();
        break;

      default:
        break;
    }

    setOpenUseModal(false);
    setModalContent(null);
    handleMessages();
  };

  const contentModalMedia = (files: File[]) => {
    const imagePromises = prepareSendFiles(files);
    const isVideoType = files[0].type.includes("video/") ? "video" : "image";

    Promise.all(imagePromises).then((images) => {
      setModalContent(
        <PreviewMediaModal
          files={files}
          images={images}
          fileType={isVideoType}
          uploadFileCallBack={uploadFileCallBack}
        />
      );
    });

    setModalContent(<AddMediaModal />);

    setOpenUseModal(true);
  };

  const contentModalPreviewPack = (files: File[]) => {
    let currentFiles = [...files];
    const removeFile = (indexToRemove: number) => {
      currentFiles = currentFiles.filter((_, index) => index !== indexToRemove);
      updateModalContent();
      if (!currentFiles.length) {
        setOpenUseModal(false);
      }
    };

    const updateModalContent = () => {
      const packPromises = prepareSendFiles(currentFiles);

      Promise.all(packPromises)
        .then((packSources) => {
          setModalContent(
            <PreviewPackModal
              files={files}
              packSources={packSources}
              removeFile={removeFile}
              uploadFileCallBack={uploadFileCallBack}
            />
          );
        })
        .catch((error) => {
          console.error("Error loading videos:", error);
        });
    };

    updateModalContent();
    setOpenUseModal(true);
  };

  const imageMessageModalHandler = (url: string) => {
    setModalContent(<ImageMessageModal url={url} />);

    setOpenUseModal(true);
  };

  const videoMessageModalHandler = (url: string) => {
    setModalContent(<VideoMessageModal url={url} />);

    setOpenUseModal(true);
  };

  const packMessageModalHandler = (
    urlsObj: {
      url: string;
      fileType: string;
      mimetype: string;
    }[]
  ) => {
    setModalContent(<PackMessageModal urlsObj={urlsObj} />);
    setOpenUseModal(true);
  };

  const sendRosesRequest = async (roses: number, message: string) => {
    const request = await postSendRoses(
      userInfo.access_token,
      userId,
      roses,
      message
    );

    setOpenUseModal(false);
    handleMessages();
  };

  const sendRosesModalHandler = () => {
    setModalContent(
      <SendRosesModal
        maxValue={userRosesAvailable}
        rosesMessageCallback={sendRosesRequest}
      />
    );
    setOpenUseModal(true);
  };

  const clickMoment = () => {};

  const sendAudioToServer = async (audioBlob: Blob | null) => {
    if (!audioBlob) return;

    const audio = new File([audioBlob], `${userInfo.user_id}`, {
      type: audioBlob.type,
    });

    const prepareData = {
      chatId,
      content: "",
      price: 55,
      paidContent: true,
      files: [audio],
    };

    const response = await postUploadMedia(userInfo.access_token, prepareData);
    scrollToBottom();
    handleMessages();
  };

  const unlockContentSubmit = (messageId: string) => {
    setModalContent(
      <UnlockContentModal messageId={messageId} unlockContent={unlockContent} />
    );
    setOpenUseModal(true);
  };

  // const loadMoreMessages = (lastMessageId: string) => {
  //   setLoading(true);
  //   const messageParams = {
  //     chatId,
  //     take: 15,
  //     cursor: lastMessageId,
  //   };

  //   getMessages(messageParams, (newMessages: Message[]) => {
  //     const filteredMessages = newMessages.filter(
  //       (msg) => msg.chatId === chatId
  //     );

  //     setMessages((prevMessages) => [...filteredMessages, ...prevMessages]);
  //     setLoading(false);
  //   });
  // };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight } = event.currentTarget;
    const chatMessages = document.getElementById("chat-messages-container");

    if (scrollTop === 0 && messages.length > 0 && !loading) {
      const firstMessageId = messages[0].messageId;
      setLoading(true);

      const messageParams = {
        chatId,
        take: 15,
        cursor: firstMessageId,
      };

      const previousHeight = chatMessages ? chatMessages.scrollHeight : 0;

      getMessages(messageParams, (newMessages: Message[]) => {
        const filteredMessages = newMessages.filter(
          (msg) => msg.chatId === chatId
        );
        setMessages((prevMessages) => [...filteredMessages, ...prevMessages]);
        setLoading(false);

        setTimeout(() => {
          if (chatMessages) {
            const newHeight = chatMessages.scrollHeight;
            chatMessages.scrollTop = newHeight - previousHeight;
          }
        }, 0);
      });
    }

    //SCROLL TO BOTTOM
    // if (scrollTop + clientHeight >= scrollHeight && !loading) {
    //   const lastMessageId = messages[messages.length - 1]?.messageId;
    //   if (lastMessageId) {
    //     setLoading(true);
    //     const messageParams = {
    //       chatId,
    //       take: 15,
    //       cursor: lastMessageId,
    //     };

    //     getMessages(messageParams, (newMessages: Message[]) => {
    //       const filteredMessages = newMessages.filter(
    //         (msg) => msg.chatId === chatId
    //       );
    //       setMessages((prevMessages) => [...prevMessages, ...filteredMessages]);
    //       setLoading(false);
    //     });
    //   }
    // }
  };

  return (
    <div className="chat-container-page">
      <PageTitle
        isChat
        title={producerDetails?.displayname}
        subtitle={isContactOnline ? "Online" : "Offline"}
        avatarUrl={producerDetails?.profile?.photos?.[0]?.url}
        onBackClick={backPageHandler}
        isOnLine={isContactOnline}
        mediaButtonClick={openMediaLibrary}
        chatLocked={chatLocked}
        clickMoment={clickMoment}
        hasAMomentToSee={hasAMomentToSee}
        className="padding-hor-24"
      />

      {chatLocked ? (
        <ChatLocked onClickButton={handleCreateChatContact} />
      ) : (
        <>
          <div
            id="chat-messages-container"
            className="messages-container"
            ref={messagesContainer}
            onScroll={handleScroll}
          >
            {messages.length <= 15 && (
              <>
                <CardExplanatory
                  title="Disappearing Messages"
                  info={`You turned on disappearing messages. All new messages
										 will disappear from this chat 24 hours after they’re 
										 sent.`}
                  linkRoute=""
                  linkText="Tap to change."
                  className="margin-bottom-16"
                />
                <CardExplanatory
                  title="Encrypted Messages"
                  info={`Messages and calls are end-to-end encrypted. No one 
										outside of this chat, not even Roses, can read or listen 
										to them.`}
                  linkRoute=""
                  linkText="Tap to learn more."
                  className="margin-bottom-16"
                />
              </>
            )}

            {messages
              .filter((msg) => msg.chatId === chatId)
              ?.map((message, index) => (
                <ChatMessage
                  key={`${message.messageId}-key${index}`}
                  side={userInfo.user_id === message.userId ? "right" : "left"}
                  message={message.content}
                  time={formattedTime(message?.createdAt || 0)}
                  fileSended={message.files}
                  messageSettings={message.messageSettings}
                  clickOnImage={imageMessageModalHandler}
                  clickOnVideo={videoMessageModalHandler}
                  unlockFileCallback={unlockContentSubmit}
                  clickOnPack={packMessageModalHandler}
                  isMessageRead={
                    message.isRead ||
                    readMessagesIds.includes(message.messageId)
                  }
                />
              ))}
          </div>

          {isRecording ? (
            <SendAudioChat
              sendAudioCallback={sendAudioToServer}
              startRecordingCallback={setIsRecording}
              className="padding-hor-24"
            />
          ) : (
            <div className="actions-input-chat padding-hor-24">
              <ChatTextarea
                onSendMessage={handleSendMessage}
                isButtonSendDisabled={isLoading}
              />
              <PinkButton
                sendPack={contentModalPreviewPack}
                // sendAudio={() => setIsRecording(true)}
                sendMedia={contentModalMedia}
                sendRoses={sendRosesModalHandler}
                // openCamera={contentModalOpenCamera}
                isButtonsDisabled={isLoading}
                className="padding-bottom-24"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ChatPage;
