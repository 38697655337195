
import { servicesApi } from "../fetchApi/services-api";

interface IBodyRequest {
  chatId: string;
  content: string;
  price: number;
  paidContent: boolean;
  files: File[];
}

const postUploadMedia = async (token: string, data: IBodyRequest) => {
  const formData = new FormData();
  formData.append("chatId", data.chatId);
  formData.append("content", data.content);
  formData.append("price", JSON.stringify(data.price));
  formData.append("paidContent", JSON.stringify(data.paidContent));

  if (data.files instanceof File) {
    formData.append("files", data.files);
  } else if (Array.isArray(data.files)) {
    data.files.forEach((file) => {
      if (file) {
        formData.append(`files`, file);
      }
    });	
  }

  try {
    const response = await fetch(`${servicesApi("chat")}/upload-media`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    return { res: data, status: response.status };
  } catch (error) {
    console.log(error);
    return { res: null, status: null };
  }
};

export default postUploadMedia;
