import React, { useState } from "react";
import "./styles.scss";

import Button from "../../Buttons/Button";
import InputText from "../../Inputs/InputText";
import ListItemSecondary from "../../Lists/ListItemSecondary";
import SubText from "../../Texts/SubText";
import infoIcon from "../../../assets/icons/union-gray.svg";
import SimpleSelect from "../../Selects/SimpleSelect";
import { dollarFormat } from "../../../utils/dollarFormat";

interface AutoRechargeProps {
  cards: {
    banner: string;
    cardType: "Debit" | "Credit";
    number: string;
  }[];
  applyRechargeCallback: (a?: any) => void;
}

const AutoRecharge: React.FC<AutoRechargeProps> = (props) => {
  const { cards, applyRechargeCallback } = props;
  const [selectedCard, setSelectedCard] = useState("");

  const applyRechargeHandler = () => {
    if (selectedCard) {
      applyRechargeCallback(selectedCard);
    }
    applyRechargeCallback();
  };

  const [lowBalance, setLowBalanceValue] = useState<string>("$25");
  const [autoRecharge, setAutoRechargeValue] = useState<string>("$100");


  return (
    <div className="auto-recharge-component margin-top-8 padding-24">
      <ListItemSecondary
        showLabel={true}
        labelContent="Auto-recharge Credits"
        showDescription={true}
        descriptionContent="Automatically recharge rose credits."
        showToggle={true}
        className="auto-recharge-list-item"
      />
      <div className="auto-recharge-input-container margin-top-16 margin-bottom-8">
        <InputText
          label="Low balance amount"
          value={lowBalance}
          onChangeInput={(amount) => { setLowBalanceValue(dollarFormat(amount)); }}
          placeholder="$25"
          inputType="text"
        />

        <InputText
          label="Auto-recharge amount"
          value={autoRecharge}
          onChangeInput={(amount) => { setAutoRechargeValue(dollarFormat(amount)); }}
          placeholder="$100"
          inputType="text"
        />

        <SimpleSelect
          className="auto-recharge-input-container__select"
          defaultValueText="Select card"
          value={selectedCard}
          onChange={setSelectedCard}
          options={cards.map((card) => {
            return {
              label: `${card.cardType} ${card.number}`,
              value: `${card.cardType} ${card.number}`,
            };
          })}
        />
      </div>
      <SubText icon={infoIcon} className={"auto-recharge-sub-text"}>
        Only payment methods available for auto recharge show here.
      </SubText>

      <Button
        buttonStyle="secondary"
        onClick={applyRechargeHandler}
        className="auto-recharge-button margin-top-16">
        Apply Auto-Recharge
      </Button>
    </div>
  );
};

export default AutoRecharge;
