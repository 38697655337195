import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import InputText from "../../components/Inputs/InputText";
import SubText from "../../components/Texts/SubText";
import disclaimerIcon from "../../assets/icons/union-gray.svg";
import Button from "../../components/Buttons/Button";
import mapMock from "../../assets/icons/image-mock/map-mock.png";
import { useState } from "react";
import chevronRightIcon from "../../assets/icons/air-plane-gray.svg";
import ListItem from "../../components/Lists/ListItem";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import Close from "../../assets/icons/closeIcons/close-icon-pink.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import InputRadio from "../../components/Inputs/InputRadio";
const UserSettingsLocationPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();

  const [residence, setResidence] = useState("Brazil");
  const countriesMock = [
    { id: 1, country: "Brazil", code: "BR" },
    { id: 2, country: "United States", code: "US" },
    { id: 3, country: "Canada", code: "CA" },
    { id: 4, country: "Argentina", code: "AR" },
    { id: 5, country: "Germany", code: "DE" },
    { id: 6, country: "France", code: "FR" },
    { id: 7, country: "Japan", code: "JP" },
    { id: 8, country: "Australia", code: "AU" },
    { id: 9, country: "India", code: "IN" },
    { id: 10, country: "China", code: "CN" },
  ];

  const changeResidence = (name: string) => {
    return setResidence(name);
  };

  const updateLocation = () => {
    /* update */
    console.log(residence);
  };

  return (
    <div className="user-settings margin-hor-24">
      <div>
        <PageTitle
          title={"Change Location"}
          subtitle={"Configuration"}
          onBackClick={() => setPrincipalScreen("/user-settings-profile")}
        />
        <InputText
          iconLeft={chevronRightIcon}
          value={residence}
          onChangeInput={() => {}}
          inputType="text"
          disabled={true}
        />

        <div className="separator-margin" />

        <InputRadio
          elements={countriesMock.map((item) => {
            return {
              groupName: "country",
              inputLabel: `${item.country}, ${item.code}`,
              inputValue: `${item.country}, ${item.code}`,
            };
          })}
          onChangeValueCallback={(e) => setResidence(e[0])}
        />

        <div className="separator-margin" />

        <Button
          children={"Upgrade to change location"}
          buttonStyle={"primary"}
          onClick={updateLocation}
        />
        <img src={mapMock} alt="" />
      </div>
    </div>
  );
};

export default UserSettingsLocationPage;
