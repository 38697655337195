 const dateShortFormated = (dateStr: string) => {
  
  const date = new Date(dateStr);

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  }).format(date);

    // Adiciona um ponto após o mês
    const [month, day, year] = formattedDate.split(' ');
    return `${month}. ${day} ${year}`;
}

export default dateShortFormated;
