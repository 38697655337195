import { servicesApi, ServiceType } from "./services-api";

type HttpMethod = "GET" | "POST" | "PATCH" | "DELETE" | "PUT";

interface FetchApiProps {
  endPoint?: string;
  service: ServiceType;
  method: HttpMethod;
  data?: object;
  token?: string;
}

const fetchApi = async (props: FetchApiProps) => {
  const { endPoint, method, service, data, token } = props;

  const apiRoute = `${servicesApi(service)}${endPoint ? endPoint : ""}`;

  const headers: HeadersInit = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config: RequestInit = {
    method,
    headers,
  };

  if (data && ["POST", "PATCH", "PUT"].includes(method)) {
    config.body = JSON.stringify(data);
    console.log(config.body)
    headers["Content-Type"] = "application/json";
  }

  try {
    const response = await fetch(apiRoute, config);

    const res = (await response?.json()) || "no-content";

    return { res, status: response.status };
  } catch (error) {
    console.log(error);
    return { res: null, status: null };
  }
};

export default fetchApi;
