import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
} from "react";
import "./styles.scss";
import VMasker from "vanilla-masker";
import Loupe from "../../../assets/icons/loupe-gray.svg";
import OpenedEye from "../../../assets/icons/eye-icon-gray.svg";
import ClosedEye from "../../../assets/icons/hide-icon-gray.svg";

interface InputTextProps {
  inputStyle?: string;
  mask?: string;
  value?: string;
  onChangeInput?: (a: string) => void;
  iconLeft?: string;
  iconRight?: string;
  label?: ReactNode;
  inputType: React.HTMLInputTypeAttribute;
  searchInput?: boolean;
  textAlign?: "start" | "end" | "left" | "right" | "center";
  isWarning?: boolean;
  textTransform?:
    | "none"
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "full-width"
    | "full-size-kana";
  placeholder?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  disabled?: boolean | undefined;
  className?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  readOnly?: boolean;
  onClick?: (a: any) => void;
}

const InputText = forwardRef<HTMLInputElement, InputTextProps>((props, ref) => {
  const {
    inputStyle,
    value = "",
    mask,
    onChangeInput,
    iconRight,
    iconLeft,
    label,
    searchInput,
    textAlign,
    inputType,
    isWarning,
    textTransform = "none",
    placeholder,
    onKeyDown,
    disabled,
    className,
    onFocus,
    readOnly,
		onClick
  } = props;
  const [inputTypeComp, setInputTypeComp] =
    useState<React.HTMLInputTypeAttribute>("text");

  useEffect(() => {
    setInputTypeComp(inputType);
  }, []);

  const handlerInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (mask) {
      onChangeInput?.(VMasker.toPattern(inputValue, mask));
    } else {
      onChangeInput?.(inputValue);
    }
  };

  const inputClassName = [
    className ?? "",
    isWarning ? "isWarning" : "",
    "input-text",
    inputStyle ?? "",
  ]
    .join(" ")
    .trim();

  return (
    <div id="input-text-default" className="input-text-default">
      {label && (
        <label className="input input-text-default__label margin-bottom-8">
          {label}
        </label>
      )}
      <div className="input-container">
        {searchInput ? (
          <img className="input-icon-left" src={Loupe} alt="loupe icon" />
        ) : iconLeft ? (
          <img className="input-icon-left" src={iconLeft} alt="" />
        ) : (
          false
        )}
        <input
          readOnly={readOnly}
          ref={ref}
          onFocus={onFocus}
          disabled={disabled}
          className={inputClassName}
          value={value}
          onClick={onClick}
          onChange={handlerInput}
          type={inputTypeComp}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          style={{
            textAlign: textAlign,
            paddingRight: searchInput || iconRight ? 40 : 16,
            paddingLeft: searchInput || iconLeft ? 40 : 16,
            textTransform: textTransform,
						cursor:inputType === "radio" ? "pointer" : "text"
          }}
        />
        {inputType === "password" && (
          <img
            className="input-icon-right"
            src={inputTypeComp === "password" ? OpenedEye : ClosedEye}
            alt={inputTypeComp === "password" ? "opened eye" : "closed eye"}
            onClick={() => {
              if (inputTypeComp === "password") {
                setInputTypeComp("text");
              } else if (inputTypeComp === "text") {
                setInputTypeComp("password");
              }
            }}
          />
        )}
        {iconRight && (
          <img className="input-icon-right" src={iconRight} alt="right icon"    onClick={onClick} />
        )}
      </div>
    </div>
  );
});

export default InputText;
