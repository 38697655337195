export const dollarFormat = (value: string) => {
  // Remove o símbolo de dólar e qualquer outro caractere não numérico
  let cleanValue = value.replace(/[^0-9]/g, '');

  // Converte o valor limpo para um número e formata com duas casas decimais
  if (cleanValue && parseFloat(cleanValue) > 0) {
    const numericValue = parseFloat(cleanValue) / 100; // Dividindo por 100 para ajustar o valor
    const formattedValue = numericValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return `$${formattedValue}`;
  } else {
    return ''; // Se o campo estiver vazio, retorna apenas o símbolo de dólar
  }
}