import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useCallback,
} from "react";
import { io, Socket } from "socket.io-client";
import { servicesApi } from "../api/fetchApi/services-api";
import { useUserInfo } from "../hooks/userInfo";

interface WebSocketContextType {
  addChatListener: (listener: (payload: any) => void) => void;
  removeListener: (type: string, listener: (payload: any) => void) => void;
  sendMessage: (payload: any, callback?: (response: any) => void) => void;
  getMessages: (payload: any, callback?: (response: any) => void) => void;
  getChats: (payload: any, callback?: (response: any) => void) => void;
  getChat: (chatId: string, callback?: (response: any) => void) => void;
  updateMessageStatus: (
    messageIds: string[],
    callback?: (response: any) => void
  ) => void;
}

const WebSocketContext = createContext<WebSocketContextType | null>(null);

export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  children,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { userInfo } = useUserInfo();
  const handleConnection = useCallback(() => {

    if (!userInfo.access_token) {
      console.error("Access token is required");
      return;
    }

    const socket = io(servicesApi("chat"), {
      transports: ["websocket"],
      auth: {
        Authorization: `Bearer ${userInfo.access_token}`,
      },
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelayMax: 5000,
      reconnectionDelay: 5000,
    });

    socket.on("connect", () => {
      console.log("Socket.IO connected");
    });

    socket.on("disconnect", () => {
      console.log("Socket.IO disconnected");
    });

    socket.on("connect_error", (error) => {
      console.error("Socket.IO connection error", error);
    });

    setSocket(socket);

    return () => {
      socket.close();
    };
  }, [userInfo.access_token]);

  useEffect(() => {
    const cleanupConnection = handleConnection();

    return cleanupConnection;
  }, [handleConnection]);

  const addChatListener = (listener: (payload: any) => void) => {
    if (socket) {
      socket.on("events", listener);

    }
  };

  const removeListener = (type: string, listener: (payload: any) => void) => {
    if (socket) {
      socket.off(type, listener);
    }
  };

  const sendMessage = (payload: any, callback?: (response: any) => void) => {

    if (socket) {
      socket.emit("publish_message", payload, callback);
    }
  };

  const getMessages = (
    payload: {
      chatId: string;
      take: number;
      onlyReadMessages: boolean;
      cursor: string;
    },
    callback?: (response: any) => void
  ) => {
    if (socket) {
      socket.emit("get_messages", payload, callback);
    }
  };

  const getChats = (
    payload: {
      chatId: string;
      take: number;
      onlyReadMessages: boolean;
      cursor: string;
    },
    callback?: (response: any) => void
  ) => {
    if (socket) {
      socket.emit("join", payload, callback);
    }
  };

  const getChat = (chatId: string, callback?: (response: any) => void) => {
    if (socket) {
      socket.emit("get_chat", { chatId }, callback);
    }
  };

  const updateMessageStatus = (
    messageIds: string[],
    callback?: (response: any) => void
  ) => {
    if (socket) {
      socket.emit(
        "update_message_status",
        { messageId: messageIds },
        (response: any) => {
          if (callback) {
            callback(response);
          }
        }
      );
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        getChat,
        addChatListener,
        removeListener,
        sendMessage,
        getMessages,
        getChats,
        updateMessageStatus,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
