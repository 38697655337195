import React, { useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import RosesLogoIconPink from "../../assets/icons/logos/logo-rose-icon-pink.svg";
import DollarIconGray from "../../assets/icons/dollar-icon-gray.svg";
import ProfileSimpleIconGray from "../../assets/icons/profile-simple-icon-gray.svg";
import EyeIconGray from "../../assets/icons/eye-icon-gray.svg";
import BalloonIconGray from "../../assets/icons/ballon-icon-gray.svg";
import ChevronRightGray from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";

const ShareContentPage: React.FC = () => {
  const [rosesAmount, setRosesAmount] = useState("");
  const [isExclusiveContent, setIsExclusiveContent] = useState(true);
  const [shareWithEveryone, setShareWithEveryone] = useState(false);
  const {
    setSecondaryScreen,
    setTertiaryScreen,
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    mainScreen,
    setMainScreen,
    params,
  } = useDeskNavigation();

  const cancelShareHandler = () => {};

  const shareContentHandler = () => {};
  const backButtonHandler = () => {
    setPrincipalScreen("");
  };
	
  return (
    <div className="share-content-page margin-hor-24">
      <PageTitle
        title={"Share Content"}
        subtitle={"Configuration"}
        onBackClick={backButtonHandler}
      />
      <ListItemSecondary
        showToggle
        onToggleChange={setIsExclusiveContent}
        showIconBorderLeft
        iconLeftContent={DollarIconGray}
        showLabel
        labelContent="Make Exclusive"
        showDescription
        descriptionContent="Monetize content"
      />
      <InputText
        inputType="text"
        value={`Roses: ${rosesAmount}`}
        iconLeft={RosesLogoIconPink}
      />
      <ListItemSecondary
        iconRightContent={ChevronRightGray}
        showIconRight
        showIconBorderLeft
        iconLeftContent={ProfileSimpleIconGray}
        showLabel
        labelContent="Add Collaborator"
        showDescription
        descriptionContent="Increase visibility"
      />
      <ListItemSecondary
        showToggle
        onToggleChange={setShareWithEveryone}
        showIconBorderLeft
        iconLeftContent={EyeIconGray}
        showLabel
        labelContent="Your feed"
        showDescription
        descriptionContent="Share with everyone"
      />
      <ListItemSecondary
        iconRightContent={ChevronRightGray}
        showIconRight
        showIconBorderLeft
        iconLeftContent={BalloonIconGray}
        showLabel
        labelContent="Message"
        showDescription
        descriptionContent="Share privately to people"
      />
      <Button buttonStyle="quaternary" onClick={cancelShareHandler}>
        Cancel
      </Button>
      <Button buttonStyle="primary" onClick={shareContentHandler}>
        Share
      </Button>
    </div>
  );
};

export default ShareContentPage;
