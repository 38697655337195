import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import InputText from "../../Inputs/InputText";
import ChevronDown from "../../../assets/icons/bx-chevron-down.svg";
import ChevronUp from "../../../assets/icons/bx-chevron-up.svg";
import Button from "../../Buttons/Button";
import InputRadio from "../../Inputs/InputRadio";
import InputCheckbox from "../../Inputs/InputCheckbox";

interface DropDownProps {
  className?: string;
  options: DropDownRadioElement[];
  setValueCallBack: (option: string[]) => void;
  placeholder?: string;
  iconLeft?: string;
  label?: string;
  textButton: string;
  dropType: "radio" | "checkbox";
  buttonCallBack?: () => void;
}

interface DropDownRadioElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
}

const DropDown: React.FC<DropDownProps> = (props) => {
  const {
    className,
    options,
    setValueCallBack,
    placeholder,
    iconLeft,
    label,
    textButton,
    dropType,
    buttonCallBack,
  } = props;

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [inputValue, setInputValue] = useState<string | string[]>("");
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target as Node)
    ) {
      setIsOpenDropDown(false);
    }
  };

  const toggleOpenDropDown = () => {
    setIsOpenDropDown((prev) => !prev);
  };

  const selectOption = (option: string[]) => {
    setInputValue(option);
    setValueCallBack(option);
  };

  const getDisplayValue = () => {
    if (dropType === "checkbox" && Array.isArray(inputValue)) {
      return inputValue.join(", ");
    }
    console.log(inputValue[0]);
    return typeof inputValue === "string" ? inputValue : inputValue[0];
  };


  
  const [isTransitionComplete, setIsTransitionComplete] = useState(false);

  useEffect(() => {
    const element = inputRadioRef.current;
    let transitionsCompleted = 0;

    if (!element) return; // Verifica se o elemento existe antes de continuar

    const handleTransitionEnd = () => {
      transitionsCompleted += 1;

      const transitionProperties = getComputedStyle(element).transitionProperty.split(", ").length;
      if (transitionsCompleted >= transitionProperties) {
        setIsTransitionComplete(true);
        transitionsCompleted = 0;
      } else {
        setIsTransitionComplete(false);
      }
    };

    element.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      element.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, []);


  const inputRadioRef = useRef<HTMLDivElement>(null);
  const inputCheckboxRef = useRef<HTMLDivElement>(null);


  return (
    <div id="drop-down-component"
      className={className ?? ""}
      data-active={isOpenDropDown}
      ref={dropDownRef}
    >
      <InputText
        label={label}
        value={getDisplayValue()}
        onClick={toggleOpenDropDown}
        readOnly
        inputType="text"
        className={`drop-down-component__input`}
        placeholder={placeholder}
        iconRight={isOpenDropDown ? ChevronUp : ChevronDown}
        iconLeft={iconLeft}
      />
      <div
        ref={inputRadioRef}
        className={`drop-down-component__input-container ${isOpenDropDown
          ? "drop-down-component__input-container-open"
          : "drop-down-component__input-container-close"
          }`}
      >
        {dropType === "radio" ? (
          <InputRadio
            elements={options}
            onChangeValueCallback={selectOption}
            className={`drop-down__item padding-top-24 ${isTransitionComplete ? "active" : ""}`}
          />
        ) : dropType === "checkbox" ? (
          <InputCheckbox
            // ref={inputCheckboxRef}
            elements={options}
            onChangeValueCallback={selectOption}
            className={`padding-top-24`}
          />
        ) : null}

        <Button buttonStyle="tertiary" onClick={toggleOpenDropDown}>
          {textButton}
        </Button>
      </div>
    </div>
  );
};

export default DropDown;
