import React, { ReactNode, useEffect, useState } from "react";
import "./styles.scss";

import Button from "../../Buttons/Button";
import infoGrayIcon from "../../../assets/icons/union-gray.svg";

interface RosesDashboardProps {
  showAddButton?: boolean;
  showRedeemButton?: boolean;
  showGoldRoses?: boolean;
  title: ReactNode;
  onClick?: () => Promise<void>;
}

const RosesDashboard: React.FC<RosesDashboardProps> = ({
  showAddButton = false,
  showRedeemButton = false,
  showGoldRoses = false,
  title = "0",
  onClick
}) => {

  return (
    <div className="roses-dashboard-container padding-24">
      <div className="text-container padding-hor-16">
        <div className="title-text margin-bottom-8">{title}</div>
        <div className="subtitle-text ">
          Available Roses
          <img src={infoGrayIcon} alt="Disclaimer Icon" className="icon" />
        </div>
        {showGoldRoses && <span className="subtitle-text gold margin-top-8">0 golden</span>}
      </div>
      {(showAddButton || showRedeemButton) && (
        <div className="button-container margin-top-24">
          {showAddButton && (
            <Button
              children={"Add Roses"}
              buttonStyle={"primary"}
              onClick={onClick}
            />
          )}
          {showRedeemButton && (
            <Button
              children={"Redeem Roses"}
              buttonStyle={"secondary"}
              onClick={onClick}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default RosesDashboard;
