import React, { ReactNode, useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ButtonNavigation from "../../components/Buttons/ButtonNavigation";
import InputText from "../../components/Inputs/InputText";
import SubText from "../../components/Texts/SubText";
import PhotosPlaceholder from "../../components/PhotosPlaceholder";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import disclaimerIcon from "../../assets/icons/union-gray.svg";
import closePeachIcon from "../../assets/icons/closeIcons/close-icon-peach.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import InfoIconGray from "../../assets/icons/union-gray.svg";
import { UserInfoRequest } from "../../types/userInfoRequest";
import SlidUp from "../../components/Modals/SlidUp";
import getUserInfo from "../../api/userInfo/getUserInfo";
import getProfileDetails from "../../api/profileDetails/getProfileDetails";
import Button from "../../components/Buttons/Button";
import patchProfileDetails from "../../api/profileDetails/patchProfileDetails";
import { textCapitalize } from "../../utils/textCapitalize";
import InputMessage from "../../components/TextAreas/Textarea";
import postUploadProfilePhotos from "../../api/profileDetails/postUploadProfilePhotos";
import deleteProfilePhotos from "../../api/profileDetails/deleteprofilePhotos";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import { ageCalculate, dateFormat } from "../../utils/dateFormat";
import DropDown from "../../components/Lists/DropDown";
import ProfileIcon from "../../assets/icons/profile-icon-gray.svg";
import AirPlaneIconGray from "../../assets/icons/air-plane-gray.svg";
import LanguageIconGray from "../../assets/icons/language-icon-gray.svg";
import LogoRosesGray from "../../assets/icons/logos/logo-rose-icon-gray.svg";

interface ListDetails {
  name: string;
  profileDetailId: number;
  type: DetailsType;
}

type DetailsType =
  | "eye_color"
  | "hair_color"
  | "nationality"
  | "ethnicity"
  | "language"
  | "gender"
  | "age"
  | "height"
  | "weight"
  | "language"
  | "occupation"
  | "marital_status"
  | "net_worth";

const UserSettingsProfilePage: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [photosAlbum, setPhotosAlbum] = useState<(File | null)[] | []>([]);
  const [isSlidUpVisible, setIsSlidUpVisible] = useState(false);
  const [isSlidUpWriteVisible, setIsSlidUpWriteVisible] = useState(false);

  const [userInfoRequest, setUserInfoRequest] =
    useState<UserInfoRequest | null>(null);
  const [eyeColorList, setEyeColorList] = useState<ListDetails[] | []>([]);
  const [hairColorList, setHairColorList] = useState<ListDetails[] | []>([]);
  const [nationalityList, setNationalityList] = useState<ListDetails[] | []>(
    []
  );
  const [ethnicityList, setEthnicityList] = useState<ListDetails[] | []>([]);
  const [languageList, setLanguageList] = useState<ListDetails[] | []>([]);
  const [genderList, setGenderList] = useState<string[] | []>([]);
  const [titleSlidUp, setTitleSlidUp] = useState("");
  const [userProfilePicture, setUserProfilePicture] = useState("");
  const [profileBirthday, setProfileBirthday] = useState("");
  const [profileHeight, setProfileHeight] = useState("");
  const [profileWeight, setProfileWeight] = useState("");
  const [openWriteInput, setOpenWriteInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<string[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [modalSlidUpContent, setModalSlidUpContent] =
    useState<ReactNode | null>(null);
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo } = useUserInfo();

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  useEffect(() => {
    const requestProfileDetails = async () => {
      const response = await getProfileDetails(userInfo.access_token);

      setEyeColorList(
        response.res
          .filter((detail: ListDetails) => detail.type === "eye_color")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setHairColorList(
        response.res
          .filter((detail: ListDetails) => detail.type === "hair_color")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setNationalityList(
        response.res
          .filter((detail: ListDetails) => detail.type === "nationality")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setEthnicityList(
        response.res
          .filter((detail: ListDetails) => detail.type === "ethnicity")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setLanguageList(
        response?.res?.filter(
          (detail: ListDetails) => detail.type === "language"
        )
      );

      setGenderList(["Man", "Woman", "Non-binary", "Trans"]);
    };

    requestProfileDetails();
  }, [userInfo]);

  useEffect(() => {
    requestUserInfo();
  }, [isSlidUpVisible, isSlidUpWriteVisible]);

  const patchDetails = async (
    key: string,
    value: string | string[] | number
  ) => {
    return await patchProfileDetails(userInfo.access_token, key, value);
  };

  const requestUserInfo = async () => {
    const response = await getUserInfo(userInfo.access_token);

    setUserProfilePicture(response.res?.photos[0]?.url || ProfileIcon);

    setUserInfoRequest(response.res);
  };

  const applyChangeDetailRequestListed = async (
    detail: string,
    valueSelected: string
  ) => {
    let type =
      detail === "gender"
        ? "gender"
        : detail === "ethnicity"
        ? "profileEthnicity"
        : detail === "eye_color"
        ? "profileEyeColor"
        : detail === "hair_color"
        ? "profileHairColor"
        : detail === "nationality"
        ? "profileNationality"
        : false;

    let value =
      detail === "gender"
        ? valueSelected?.toUpperCase()
        : valueSelected?.toLowerCase();

    if (type) {
      await patchDetails(type, value);
    }

    setIsSlidUpVisible(false);
  };

  const changeListItemHandler = (toChange: DetailsType) => {
    const detailsSlide =
      toChange === "gender"
        ? { list: genderList, title: "Gender" }
        : toChange === "ethnicity"
        ? { list: ethnicityList, title: "Ethnicity" }
        : toChange === "eye_color"
        ? { list: eyeColorList, title: "Eye Color" }
        : toChange === "hair_color"
        ? { list: hairColorList, title: "Hair Color" }
        : toChange === "nationality"
        ? { list: nationalityList, title: "Nationality" }
        : { list: [], title: "" };

    setTitleSlidUp(detailsSlide?.title);

    setIsSlidUpVisible(true);
    let valueSelected: string;
    const selectedValueHandler = (value: string) => {
      valueSelected = value;
    };
    setModalSlidUpContent(
      <>
        {detailsSlide.list?.map((detail: any) => (
          <ListItemSecondary
            key={detail}
            showLabel
            labelContent={textCapitalize(detail)}
            showRadioRight
            radioRightGroupName={toChange}
            radioRightValue={detail}
            onChangeRightRadioCallback={selectedValueHandler}
            showSeparator
          />
        ))}

        <Button
          onClick={() =>
            applyChangeDetailRequestListed(toChange, valueSelected)
          }
          buttonStyle="primary"
        >
          Apply
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpVisible(false)}
        >
          Cancel
        </Button>
      </>
    );
  };

  const addListLanguage = async (lang: string[]) => {
    const response = await patchDetails("profileLanguages", lang);

    switch (response.status) {
      case 200:
        setIsSlidUpVisible(false);
        break;
    }
  };

  const addSpecification = async () => {
    let key = openWriteInput === "birthday" ? "birthDate" : openWriteInput;
    let value =
      openWriteInput === "weight"
        ? Number(profileWeight)
        : openWriteInput === "height"
        ? Number(profileHeight)
        : profileBirthday;

    const response = await patchDetails(key, value);
    switch (response.status) {
      case 200:
        setIsSlidUpWriteVisible(false);
        break;
    }
  };

  const addPhotoHandler = async (files: (File | null)[]): Promise<void> => {
    setPhotosAlbum(files);
    const imageToUpload = files.filter((file) => file !== null);
    const photo = imageToUpload[imageToUpload.length - 1];
    if (photo) {
      await postUploadProfilePhotos(userInfo.access_token, photo);
      requestUserInfo();
    }
  };

  const removePhotoHandler = async (id: string) => {
    await deleteProfilePhotos(userInfo.access_token, id);
    requestUserInfo();
  };

  const formatHeight = () => {
    if (userInfoRequest?.height) {
      const feetConverter = Number(userInfoRequest?.height) * 0.0328084;
      const meters = `${
        userInfoRequest?.height.toString()[0]
      },${userInfoRequest?.height.toString().slice(1)}`;

      const feet = `${feetConverter.toString()[0]}’${feetConverter
        .toString()
        .slice(1)
        .substring(1, 3)}”`;

      return `${feet} / ${meters}`;
    } else {
      return "Add";
    }
  };

  const formatWeight = () => {
    if (userInfoRequest?.weight) {
      const poundConverter = Number(userInfoRequest?.weight) * 2.205;

      const kilo = `${userInfoRequest?.weight}kg`;
      const pounds = `${poundConverter.toFixed(2).replace(".", ",")}lb`;
      return `${kilo} / ${pounds}`;
    } else {
      return "Add";
    }
  };

  const selectLanguageHandler = () => {
    let selectedLanguageList: string[] = [];
    const handleCheckboxRightChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const value = event.target.value;

      if (event.target.checked) {
        if (!selectedLanguageList.includes(value)) {
          selectedLanguageList = [...selectedLanguageList, value];
        }
      } else {
        selectedLanguageList = selectedLanguageList.filter(
          (lang) => lang !== value
        );
      }
    };

    setTitleSlidUp("Language");

    setModalSlidUpContent(
      <>
        {languageList.map(
          (
            lang: { name: string; profileDetailId: number; type: string },
            index
          ) => (
            <ListItemSecondary
              key={index}
              showLabel
              labelContent={textCapitalize(lang.name)}
              showCheckboxRight
              checkBoxRightHandler={handleCheckboxRightChange}
              checkboxRightValue={lang.name}
              // setCheckRightSelect={setSelectedLanguageList}
              checkRightSelect={selectedLanguageList}
              showSeparator
              radioRightGroupName="language"
            />
          )
        )}

        <Button
          onClick={() => addListLanguage(selectedLanguageList)}
          buttonStyle="primary"
        >
          Apply
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpVisible(false)}
        >
          Cancel
        </Button>
      </>
    );
    setIsSlidUpVisible(true);
  };

  const personalInfoData = [
    {
      labelContent: "Profile",
      valueContent: textCapitalize(userInfo.display_name) || "Add",
      clickAction: () => setPrincipalScreen("/user-settings-edit-profile"),
    },
    {
      labelContent: "Bio",
      valueContent: textCapitalize(userInfoRequest?.bio) || "Add",
      clickAction: () => {},
    },
    {
      labelContent: "Gender",
      valueContent: textCapitalize(userInfoRequest?.gender) || "Add",
      clickAction: () => changeListItemHandler("gender"),
    },
    {
      labelContent: "Age",
      valueContent: ageCalculate(userInfoRequest?.birthDate) || "Add",
      clickAction: () => {
        setIsSlidUpWriteVisible(true);
        setOpenWriteInput("Age");
      },
    },
    {
      labelContent: "Ethnicity",
      valueContent: textCapitalize(userInfoRequest?.ethnicity?.name) || "Add",
      clickAction: () => changeListItemHandler("ethnicity"),
    },
    {
      labelContent: "Height",
      valueContent: formatHeight(),
      clickAction: () => {
        setIsSlidUpWriteVisible(true);
        setOpenWriteInput("height");
      },
    },
    {
      labelContent: "Weight",
      valueContent: formatWeight(),
      clickAction: () => {
        setIsSlidUpWriteVisible(true);
        setOpenWriteInput("weight");
      },
    },
    {
      labelContent: "Language",
      valueContent: userInfoRequest?.languages?.length
        ? userInfoRequest.languages
            .map((lang) => textCapitalize(lang.name))
            .join(", ")
        : "Add",
      clickAction: selectLanguageHandler,
    },
    {
      labelContent: "Eye color",
      valueContent: textCapitalize(userInfoRequest?.eyeColor?.name) || "Add",
      clickAction: () => changeListItemHandler("eye_color"),
    },
    {
      labelContent: "Hair color",
      valueContent: textCapitalize(userInfoRequest?.hairColor?.name) || "Add",
      clickAction: () => changeListItemHandler("hair_color"),
    },

    {
      labelContent: "Nationality",
      valueContent: textCapitalize(userInfoRequest?.nationality?.name) || "Add",
      clickAction: () => changeListItemHandler("nationality"),
    },
  ];

  const preferenceListData = [
    {
      showIconLeft: false,
      iconLeftContent: "",
      showIconBorderLeft: false,
      showLabel: true,
      labelContent: "Connect & Chat",
      showDescription: false,
      descriptionContent: "",
      showToggle: false,
      showIconRight: true,
      iconRightContent: chevronRightIcon,
      showValue: true,
      valueContent: "Free",
    },
    {
      showIconLeft: false,
      iconLeftContent: "",
      showIconBorderLeft: false,
      showLabel: true,
      labelContent: "Photos",
      showDescription: false,
      descriptionContent: "",
      showToggle: false,
      showIconRight: true,
      iconRightContent: chevronRightIcon,
      showValue: true,
      valueContent: "50",
    },
    {
      showIconLeft: false,
      iconLeftContent: "",
      showIconBorderLeft: false,
      showLabel: true,
      labelContent: "Packs",
      showDescription: false,
      descriptionContent: "",
      showToggle: false,
      showIconRight: true,
      iconRightContent: chevronRightIcon,
      showValue: true,
      valueContent: "100",
    },
  ];

  const profilePictureChangeHandler = () => {};

  const countriesMock = [
    { id: 1, country: "Brazil", code: "BR" },
    { id: 2, country: "United States", code: "US" },
    { id: 3, country: "Canada", code: "CA" },
    { id: 4, country: "Argentina", code: "AR" },
    { id: 5, country: "Germany", code: "DE" },
    { id: 6, country: "France", code: "FR" },
    { id: 7, country: "Japan", code: "JP" },
    { id: 8, country: "Australia", code: "AU" },
    { id: 9, country: "India", code: "IN" },
    { id: 10, country: "China", code: "CN" },
  ];

  const languagesMock = [
    { id: 1, name: "English" },
    { id: 2, name: "Spanish" },
    { id: 3, name: "Portuguese" },
    { id: 4, name: "French" },
    { id: 5, name: "German" },
    { id: 6, name: "Italian" },
    { id: 7, name: "Japanese" },
    { id: 8, name: "Chinese" },
    { id: 9, name: "Russian" },
    { id: 10, name: "Arabic" },
  ];

  return (
    <div className="user-settings-profile">
      <PageTitle
        title={"Profile"}
        subtitle={"Configuration"}
        onBackClick={() => setPrincipalScreen("/user-settings")}
        hasCloseIcon={false}
        className="padding-hor-24"
      />
      <ButtonNavigation
        setActiveSection={handleSectionChange}
        activeSection={activeSection}
        buttonCount={3}
        buttonLabels={["Info", "Photos", "Preference"]}
        className="margin-24"
      />
      {activeSection === "button1" && (
        <>
          <div className="profile-picture-container margin-hor-24">
            <div className="profile-picture-container__photo">
              <img src={userProfilePicture} alt="user profile" />
            </div>
            <Button
              buttonStyle="tertiary"
              onClick={profilePictureChangeHandler}
            >
              Profile Picture
            </Button>
          </div>
        </>
      )}

      {activeSection === "button1" && (
        <>
          <div className="list-container-personal-info margin-24">
            {personalInfoData.map((item, index) => (
              <ListItemSecondary
                key={index}
                showLabel={true}
                labelContent={item.labelContent}
                showIconRight={true}
                showValue={true}
                valueContent={item.valueContent}
                iconRightContent={chevronRightIcon}
                showSeparator={true}
                clickAction={item.clickAction}
                className="list-container-personal-info_item"
              />
            ))}
          </div>
        </>
      )}

      {activeSection === "button2" && (
        <>
          <div className="user-settings-photo-container margin-hor-24">
            <PhotosPlaceholder
              quantityFiles={9}
              photoAlbumCallback={addPhotoHandler}
              images={userInfoRequest?.photos}
              removeFileCallback={removePhotoHandler}
            />
            <p className="album-photos-quantity no-margin">
              {userInfoRequest?.photos?.length} / 9 photos
            </p>
          </div>
          <section className="album-photos-quantity-list-container margin-hor-24 margin-bottom-24">
            <ListItemSecondary
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Preserve my Confidentiality"
              showDescription={true}
              descriptionContent="Conceal my facial identity"
              showToggle={true}
              showSeparator={true}
            />
            <ListItemSecondary
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Smart Photo"
              showDescription={true}
              descriptionContent="Chooses best photo for you"
              showToggle={true}
            />
          </section>
          <SubText
            children={`We welcome sensual expression that's artistic and 
							sophisticated. Please avoid explicit content to maintain the 
							respectful and tasteful spirit of our community.`}
            icon={disclaimerIcon}
          />
        </>
      )}

      {activeSection === "button3" && (
        <section className="user-settings-preferences margin-hor-24">
          <DropDown
            className="margin-vert-24"
            setValueCallBack={setSelectedLocation}
            placeholder="Select country"
            iconLeft={AirPlaneIconGray}
            options={countriesMock.map((country) => {
              return {
                groupName: "country",
                inputLabel: `${country.country}, ${country.code}`,
                inputValue: `${country.country}, ${country.code}`,
              };
            })}
            textButton="Add location"
            label="Location"
            dropType="radio"
            buttonCallBack={() => setPrincipalScreen("/user-settings-location")}
          />

          <DropDown
            textButton="Add language"
            dropType="checkbox"
            label="Language"
            setValueCallBack={setSelectedLanguages}
            placeholder="Select language"
            iconLeft={LanguageIconGray}
            options={languagesMock.map((language) => {
              return {
                groupName: "country",
                inputLabel: `${language.name}`,
                inputValue: `${language.name}`,
              };
            })}
            className="margin-vert-24"
          />

          <div className="list-container margin-vert-24">
            <div className="list-container-settings">
              {preferenceListData.map((item, index) => (
                <ListItemSecondary
                  key={index}
                  showIconLeft={item.showIconLeft}
                  iconLeftContent={item.iconLeftContent}
                  showIconBorderLeft={item.showIconBorderLeft}
                  showLabel={item.showLabel}
                  labelContent={item.labelContent}
                  showDescription={item.showDescription}
                  descriptionContent={item.descriptionContent}
                  showToggle={item.showToggle}
                  showIconRight={item.showIconRight}
                  iconRightContent={item.iconRightContent}
                  showValue={item.showValue}
                  valueContent={item.valueContent}
                  showSeparator={preferenceListData.length > index + 1}
                  className="list-container-settings__list"
                />
              ))}
            </div>
          </div>
          <Button buttonStyle="tertiary">Add Content</Button>
          <ListItemSecondary
            showIconBorderLeft
            showIconLeft
            iconLeftContent={LogoRosesGray}
            showLabel
            labelContent="Allow Requests"
            showDescription
            descriptionContent="Accept roses requests from everyone"
            showToggle
            showIconRight
            className="margin-vert-16"
          />
        </section>
      )}

      <SlidUp
        classNameContainer="user-settings-slid-up"
        classNameChildren="list-item-slid-up "
        isOpen={isSlidUpVisible}
        title={titleSlidUp}
      >
        {modalSlidUpContent}
      </SlidUp>

      <SlidUp
        classNameContainer="user-settings-slid-up"
        classNameChildren="list-item-slid-up"
        isOpen={isSlidUpWriteVisible}
        title={openWriteInput}
      >
        {openWriteInput === "birthday" ? (
          <InputText
            inputType="text"
            value={profileBirthday}
            onChangeInput={(e) => setProfileBirthday(e)}
            placeholder="YYYY/MM/DD"
            mask="9999/99/99"
          />
        ) : openWriteInput === "weight" ? (
          <InputText
            inputType="text"
            value={profileWeight}
            onChangeInput={(e) => setProfileWeight(e)}
            placeholder="Weight"
            // placeholder="YYYY/MM/DD"
            // mask="9999/99/99"
          />
        ) : openWriteInput === "height" ? (
          <InputText
            value={profileHeight}
            inputType="text"
            onChangeInput={(e) => setProfileHeight(e)}
            placeholder="Height"
            // mask="9999/99/99"
          />
        ) : (
          ""
        )}
        <Button onClick={() => addSpecification()} buttonStyle="primary">
          Apply
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpWriteVisible(false)}
        >
          Cancel
        </Button>
      </SlidUp>
    </div>
  );
};

export default UserSettingsProfilePage;
