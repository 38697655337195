import React, { ReactNode } from "react";
import "./styles.scss";

import ChevronClose from "../../assets/icons/closeIcons/close-icon-gray.svg";
import ChevronLeft from "../../assets/icons/navigationIcons/arrow-left-gray.svg";
import photoDefault from "../../assets/images/roses-photo-default.png";
import ProfileIcon from "../../assets/icons/profile-icon-gray.svg";

interface PageTitleProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  onBackClick?: () => void;
  isChat?: boolean;
  avatarUrl?: string;
  isOnLine?: boolean;
  mediaButtonClick?: (a: any) => void;
  hasCloseIcon?: boolean;
  chatLocked?: boolean;
  hasAMomentToSee?: boolean;
  clickMoment?: (a: any) => void;
  className?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  subtitle,
  onBackClick,
  isChat,
  avatarUrl,
  isOnLine,
  mediaButtonClick,
  hasCloseIcon = true,
  chatLocked,
  hasAMomentToSee,
  clickMoment,
  className,
}) => {
  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    }
  };

  return (
    <div className={`page-title-container ${className ?? ""}`}>
      <div className="back-button" onClick={handleBackClick}>
        {/* <img
          src={hasCloseIcon ? ChevronClose : ChevronLeft}
          alt="x back icon"
        /> */}
        {hasCloseIcon ? (
          <span className="icon icon-close icon-md icon-gray"></span>
        ) : (
          <span className="icon icon-chevron-left icon-md icon-gray"></span>
        )}
      </div>
      {isChat && clickMoment && (
        <div
          onClick={clickMoment}
          className={`chat-icon margin-right-8 ${
            hasAMomentToSee ? "new-moment-available" : "moment-was-seen"
          }`}
        >
          <img
            className="chat-icon-img padding-4"
            src={avatarUrl || ProfileIcon}
            alt="chat icon"
          />
        </div>
      )}
      <div className="text-wrapper">
        <div className={`title ${isChat ? "chat-title" : ""}`}>{title}</div>
        {subtitle && (
          <div className="subtitle-wrapper">
            {isChat && (
              <div
                className={`chat-icon-status ${
                  isOnLine ? "interest-online" : "interest-offline"
                }`}
              />
            )}
            <div className="subtitle">{subtitle}</div>
          </div>
        )}
      </div>
      {isChat && !chatLocked && (
        <div className="open-media">
          <button className="open-media-button" onClick={mediaButtonClick}>
            MEDIA
          </button>
        </div>
      )}
    </div>
  );
};

export default PageTitle;
