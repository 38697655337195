import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import CloseIconWhite from "../../assets/icons/closeIcons/close-icon-white.svg";
import { useModal } from "../../hooks/useModal";
import { thumbnailGenerator } from "../../utils/thumbnailGenerator";
import InputText from "../../components/Inputs/InputText";
import PlusIconGray from "../../assets/icons/more-icon-gray.svg";
import ArrowRightPink from "../../assets/icons/arrow-right-pink.svg";
import IconFilterGray from "../../assets/icons/iconfilter.svg";
import DigitalPrintIconPink from "../../assets/icons/digital-print-icon-pink.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useWindowWidthSize } from "../../hooks/useWindowWidthSize";

type ICapturedMedia = {
  type: "video" | "photo";
  media: string;
  thumbnail?: string;
};

const AddMediaPage: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [showMedia, setShowMedia] = useState(false);
  const [mediaToShow, setMediaToShow] = useState<ICapturedMedia | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const recordingButtonRef = useRef<HTMLButtonElement>(null);
  const [capturedMedia, setCapturedMedia] = useState<ICapturedMedia[] | []>([]);
  const { setOpenUseModal, setModalContent } = useModal();
  const [isHolding, setIsHolding] = useState(false);
  const [descriptionContent, setDescriptionContent] = useState("");
  const [showPixelShieldPill, setShowPixelShieldPill] = useState(false);
  let holdTimeout: NodeJS.Timeout;
  const {
    setSecondaryScreen,
    setTertiaryScreen,
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    mainScreen,
    setMainScreen,
    params,
  } = useDeskNavigation();
  const { setComponent } = useLoginSignUpNavigation();
  const { windowSize } = useWindowWidthSize();

  useEffect(() => {
    startCamera();
  }, []);

  const closeModal = () => {
    stopRecording();
    setMainScreen("");
  };

  const startCamera = async () => {
    setShowMedia(false);
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        setStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      } else {
        setError("Error");
      }
    } catch (err) {
      setError((err as Error).message);
    }
  };

  const handleMouseDown = () => {
    setIsHolding(false);

    holdTimeout = setTimeout(() => {
      setIsHolding(true);
      startRecording();
    }, 1000);
  };

  const handleMouseUp = () => {
    clearTimeout(holdTimeout);
    if (!isHolding) {
      capturePhoto();
    } else {
      stopRecording();
    }
  };

  const handleMouseLeave = () => {
    clearTimeout(holdTimeout);
    if (isHolding) {
      stopRecording();
    }
  };

  const capturePhoto = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        context.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        const imageDataURL = canvasRef.current.toDataURL("image/png");
        setMediaToShow({ media: imageDataURL, type: "photo" });
        setCapturedMedia((prev) => [
          ...prev,
          { media: imageDataURL, type: "photo" },
        ]);
        setShowMedia(true);
      }
    }
  };

  const startRecording = () => {
    if (stream) {
      try {
        const options = { mimeType: "video/webm; codecs=vp8" };

        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          options.mimeType = "video/webm";
        }

        const mediaRecorder = new MediaRecorder(stream, options);
        mediaRecorderRef.current = mediaRecorder;
        const localChunks: Blob[] = [];
        mediaRecorder.start();
        setIsRecording(true);

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            localChunks.push(event.data);
          }
        };

        mediaRecorder.onstop = () => {
          if (localChunks.length > 0) {
            const blob = new Blob(localChunks, { type: "video/webm" });

            const videoURL = URL.createObjectURL(blob);
            setMediaToShow({ media: videoURL, type: "video" });
            setRecordedChunks([]);

            thumbnailGenerator(videoURL).then((thumbnail) => {
              setCapturedMedia((prev) => [
                ...prev,
                { media: videoURL, thumbnail, type: "video" },
              ]);
            });

            setShowMedia(true);
          }
        };

        mediaRecorder.onerror = (error) => {
          console.error("Error: ", error);
        };
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      videoRef.current?.pause();
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const pixelShieldFilterHandler = () => {
    setShowPixelShieldPill((prev) => !prev);
  };

  const shareContentSettings = () => {
    setPrincipalScreen("/share-content");
  };

  return (
    <div className="add-media-page">
      <section className="media-selection-config">
        <section className="media-selection-config__image-container">
          <button
            className="image-container__pixel-shield-button"
            onClick={pixelShieldFilterHandler}
          >
            <img src={IconFilterGray} alt="filter icon" />
          </button>
          {showPixelShieldPill && (
            <div className="image-container__pixel-shield-pill">
              <img src={DigitalPrintIconPink} alt="digital print icon" />
              <p className="pixel-shield-button__text">Pixel Shield</p>
            </div>
          )}

          <img
            src={CloseIconWhite}
            alt="close icon"
            className="image-container__close-icon"
            onClick={closeModal}
          />

          {showMedia ? (
            <>
              {mediaToShow?.type === "photo" ? (
                <img src={mediaToShow.media} alt="media captured" />
              ) : (
                <video key={mediaToShow?.media} controls className="image-container__video-captured">
                  <source src={mediaToShow?.media} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
              )}
            </>
          ) : (
            <>
              <video className="image-container__image" ref={videoRef} />
              <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
              <div className="image-container__button-container">
                <button
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseLeave}
                  className="button-container__recording-button"
                  ref={recordingButtonRef}
                ></button>
              </div>
            </>
          )}
        </section>
        <section
          className={`image-container__preview-media ${
            showMedia ? "position-absolute" : ""
          } ${
            capturedMedia.length <= 6 ? "justify-center" : ""
          } margin-vert-16`}
        >
          <div className="preview-media">
            {capturedMedia?.map((media, index) => (
              <img
                key={index}
                src={media.type === "photo" ? media.media : media.thumbnail}
                alt={`capture ${index}`}
                className="preview-media__media"
              />
            ))}
          </div>
        </section>
        {showMedia && (
          <section className="image-container__user-actions-container margin-hor-24">
            <button
              className="user-actions-container__plus-button"
              onClick={startCamera}
            >
              <img src={PlusIconGray} alt="plus icon" />
            </button>
            <InputText
              inputType="text"
              placeholder="Description of content"
              value={descriptionContent}
              onChangeInput={setDescriptionContent}
            />
            <button
              className="user-actions-container__next-step-button"
              onClick={shareContentSettings}
            >
              <img src={ArrowRightPink} alt="arrow right icon" />
            </button>
          </section>
        )}
      </section>
    </div>
  );
};

export default AddMediaPage;
