import { useState } from "react";
import Header from "../../components/Headers/Header";
import "./styles.scss";
import EyeIcon from "../../assets/icons/eye-icon-gray.svg";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import LockIcon from "../../assets/icons/lock-icon-gray.svg";
import { useNavigate } from "react-router-dom";
import { IToast } from "../../types/toast";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import postCreateUser from "../../api/auth/postCreateUser";
import Toast from "../../components/Toast";
import getUserSelfDetail from "../../api/getUserSelfDetail";
import createUser from "../../api/auth/postCreateUser";
import PasswordStrengthBar from "react-password-strength-bar";

const CreateAccountPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userNameWarning, setUserNameWarning] = useState(false);
  const [createdPassword, setCreatedPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordInputWarning, setPasswordInputWarning] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [isSignNewsLetter, setIsSignNewsLetter] = useState(true);

  const { setComponent } = useLoginSignUpNavigation();
  const { setUserInfo, userInfo } = useUserInfo();
  const navigate = useNavigate();

  const createAccountSubmit = async () => {
    if (userName.trim().length < 3) {
      setToastConfig({
        description: "Your display name should be at least 3 characters long",
        title: "Short Display name",
        type: "warning",
      });

      setToastShow(true);
      setUserNameWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setUserNameWarning(false);
      }, 4000);
    } else if (confirmPassword.trim().length < 8) {
      setToastConfig({
        description: "Your password should be at least 8 characters long",
        title: "Short Pass",
        type: "warning",
      });

      setToastShow(true);
      setPasswordInputWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setPasswordInputWarning(false);
      }, 4000);
    } else if (createdPassword.trim() !== confirmPassword.trim()) {
      setToastConfig({
        description: "Check passwords and try again",
        title: "Passwords don't match",
        type: "warning",
      });

      setToastShow(true);
      setPasswordInputWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setPasswordInputWarning(false);
      }, 4000);
    } else {
      setIsLoading(true);

      const createdUserData = {
        displayname: userName,
        email: userEmail,
        password: confirmPassword.trim(),
      };

      const createUserResponse = await createUser(createdUserData);

      switch (createUserResponse.status) {
        case 201:
          // The cleaners below keep the information up to date
          localStorage.clear();
          sessionStorage.clear();
          const accessToken = createUserResponse.res.access_token;

          setUserInfo("access_token", accessToken);

          const activateResponse = await getUserSelfDetail(accessToken);

          switch (activateResponse?.status) {
            case 200:
              setUserInfo("user_id", activateResponse.res.userId);
              setUserInfo("display_name", activateResponse.res.displayname);
              setUserInfo("account_type", activateResponse.res.role);
              setUserInfo("user_email", activateResponse.res.email);
              setUserInfo("user_phone", activateResponse.res.phone);

              navigate("/app");

              break;
            default:
              setToastConfig({
                description:
                  "Something went wrong on user activate. Please try again.",
                title: "Error",
                type: "error",
              });
              setToastShow(true);
              setTimeout(() => setToastShow(false), 3000);
              break;
          }

          break;

        default:
          setToastConfig({
            description:
              "Something went wrong on user creation. Please try again.",
            title: "Error",
            type: "error",
          });
          setToastShow(true);

          setTimeout(() => setToastShow(false), 3000);
          break;
      }

      setIsLoading(false);
    }
  };

  return (
    <div className="create-account-page">
      <Header
        headerIcon="back-button"
        titleClass="header"
        backButtonClick={() => setComponent("/account-login")}
        title={
          <>
            Create your <br />
            account
          </>
        }
      />
      <div className="input-container">
        <InputText
          value={userName}
          placeholder="Name"
          onChangeInput={(e) => setUserName(e)}
          isWarning={userNameWarning}
          className="no-margin"
          inputType="text"
        />
        <SubText icon={EyeIcon} altIcon="clock icon">
          This will be shown on your profile
        </SubText>
        <hr className="no-margin" />
        <InputText
          value={userEmail}
          placeholder="Email"
          onChangeInput={(e) => setUserEmail(e)}
          isWarning={userNameWarning}
          className="no-margin"
          inputType="text"
        />
        <SubText
          checkAnswer
          checkValueCallback={setIsSignNewsLetter}
          isChecked={isSignNewsLetter}
        >
          I want to receive news, updates, and offers from Roses
        </SubText>
        <InputText
          value={createdPassword}
          placeholder="Password"
          inputType="password"
          onChangeInput={(e) => setCreatedPassword(e)}
          isWarning={passwordInputWarning}
          className="no-margin"
        />

        <InputText
          value={confirmPassword}
          placeholder="Confirm Password"
          inputType="password"
          onChangeInput={(e) => setConfirmPassword(e)}
          isWarning={passwordInputWarning}
          className="no-margin"
        />
        <PasswordStrengthBar
          minLength={8}
          barColors={[
            "var(--charcoal)",
            "var(--peach)",
            "#FFC045",
            "var(--green)",
            "var(--green",
          ]}
          scoreWords={[
            "(add more characters to strengthen) very weak",
            "(add more characters to strengthen) weak",
            "good",
            "strong",
            "very strong",
          ]}
          className="teste"
          scoreWordClassName="texto"
          shortScoreWord="Your password should be at least 8 characters long"
          password={createdPassword}
        />
      </div>
      <div className="buttons-container margin-top-24">
        <Button
          disabled={isLoading}
          buttonStyle="quaternary"
          onClick={() => setComponent("/account-login")}
        >
          Already have an account?<span className="highlight">Login</span>
        </Button>
        <Button
          disabled={isLoading}
          buttonStyle="primary"
          onClick={createAccountSubmit}
        >
          Create Account
        </Button>
      </div>
      <Toast
        type={toastConfig.type}
        description={toastConfig.description}
        setIsVisible={setToastShow}
        isVisible={toastShow}
        title={toastConfig.title}
      />
    </div>
  );
};

export default CreateAccountPage;
