import { useModal } from "../../../../hooks/useModal";
import React, { useRef, useState } from "react";
import rosesLogoPink from "../../../../assets/icons/logos/logo-fill-pink.svg";
import "../chat-modal-global-style.scss";
import Button from "../../../Buttons/Button";
import "./styles.scss";
import LessIcon from "../../../../assets/icons/less-gray-icon.svg";
import MoreIcon from "../../../../assets/icons/more-icon-gray.svg";
import Range from "../../../Ranges/Range";
import InputMessage from "../../../TextAreas/Textarea";

interface SendRosesModalProps {
  maxValue: string | number;
  rosesMessageCallback: (roses: number, message: string) => void;
}

const SendRosesModal: React.FC<SendRosesModalProps> = (props) => {
  const { maxValue, rosesMessageCallback } = props;
  const { setOpenUseModal } = useModal();
  const [rosesToSend, setRosesToSend] = useState<string | number>(0);
  const [messageToSend, setMessageToSend] = useState("");
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const startIncrement = () => {
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setRosesToSend((prevCount) => Number(prevCount) + 1);
      }, 100);
    }
  };

  const startDecrement = () => {
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setRosesToSend((prevCount) => Number(prevCount) - 1);
      }, 100);
    }
  };

  const stopAction = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return (
    <div className="send-roses-modal">
      <div className="send-roses-modal__wrapper">
        <img src={rosesLogoPink} alt="roses logo icon" />
        <h3>Send Roses</h3>
        <p>
          Capture Your Love's Heart: <br />
          Send <b>Roses</b> Now!
        </p>

        <section className="range-roses-to-send">
          <div
            className="range-roses-to-send__less"
            onMouseDown={startDecrement}
            onMouseUp={stopAction}
            onMouseLeave={stopAction}
            onClick={() => setRosesToSend((prev) => Number(prev) - 1)}
          >
            <img src={LessIcon} alt="less icon" />
          </div>
          <div className="range-roses-to-send__value">
            <p className="value-to-send">{rosesToSend}</p>
            <p className="available-value">{maxValue} available</p>
          </div>
          <div
            className="range-roses-to-send__more"
            onMouseDown={startIncrement}
            onMouseUp={stopAction}
            onMouseLeave={stopAction}
            onClick={() => setRosesToSend((prev) => Number(prev) + 1)}
          >
            <img src={MoreIcon} alt="more icon" />
          </div>
          <Range
            max={Number(maxValue)}
            min={0}
            rangeCallback={(e) => setRosesToSend(e)}
            className="range-roses-to-send__range margin-vert-24"
            value={rosesToSend}
          />
        </section>

        <InputMessage
          value={messageToSend}
          placeholder="Add a message..."
          onChangeInput={(e) => setMessageToSend(e)}
        />

        <div className="buttons-container">
          <Button
            buttonStyle="primary"
            onClick={() =>
              rosesMessageCallback(Number(rosesToSend), messageToSend)
            }
          >
            Send Roses
          </Button>
          <Button
            buttonStyle="quaternary"
            onClick={() => setOpenUseModal(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SendRosesModal;
