import React from "react";
import "./styles.scss";
import ListItem from "../../Lists/ListItem";
import ImageToggle from "../../Toggle/ImageToggle";
import ChatImageAnimation from "../../Chat/ChatImageAnimation";
import mapShieldImageOn from "../../../assets/images/landingPageImages/roses-location-shield-on.png";
import mapShieldImageOff from "../../../assets/images/landingPageImages/roses-location-shield-off.png";
import pixelShieldImageOn from "../../../assets/images/landingPageImages/roses-pixel-shield-on.png";
import pixelShieldImageOff from "../../../assets/images/landingPageImages/roses-pixel-shield-off.png";
import iconDigitalPrintGray from "../../../assets/icons/digital-print-icon-gray.svg";

interface ContentWrapperProps {
  contentSide?: "left" | "right";
  listItemCount?: number;
  featureIcon: string;
  featureTitle: string;
  featureSubTitle: string;
  featureDescription: string;
  listItem1LabelContent?: string;
  listItem1DescriptionContent?: string;
  listItem2LabelContent?: string;
  listItem2DescriptionContent?: string;
  componentType?: "map" | "pixel" | "chat";
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({
  contentSide = "left",
  listItemCount = 1,
  featureIcon,
  featureTitle,
  featureSubTitle,
  featureDescription,
  listItem1LabelContent,
  listItem1DescriptionContent,
  listItem2LabelContent,
  listItem2DescriptionContent,
  componentType = "map",
}) => {
  const isLeft = contentSide === "left";
  const wrapperClass = isLeft ? "left-content" : "right-content";

  return (
    <div id="content-web-site-wrapper" className={wrapperClass}>
      {isLeft ? (
        <>
          <div className="yellow-div padding-24">
            <div className="text-container">
              <img className="margin-bottom-24" src={featureIcon} alt="Icon" />
              <h4 textkey={featureTitle} />
              <p textkey={featureSubTitle} />
              <span>by Roses™</span>
              <div className="feature-description padding-vert-24">
                <p textkey={featureDescription} />
              </div>
              {listItemCount > 0 && (
                <ListItem
                  showSphereIcon
                  showLabel
                  labelContent={listItem1LabelContent}
                  showDescription
                  descriptionContent={listItem1DescriptionContent}
                />
              )}
              {listItemCount > 1 && <div className="divider"></div>}
              {listItemCount > 1 && (
                <ListItem
                  showSphereIcon
                  showLabel
                  labelContent={listItem2LabelContent || ""}
                  showDescription
                  descriptionContent={listItem2DescriptionContent || ""}
                />
              )}
            </div>
          </div>
          <div className="purple-div">
            {componentType === "map" && (
              <ImageToggle
                imageOn={mapShieldImageOn}
                imageOff={mapShieldImageOff}
                labelContent={"5/locationListItemTitle"}
                descriptionContent={"5/locationListItemDescription"}
                showIconLeft={false}
                showModal={true}
                borderRadiusSide="right"
              />
            )}
            {componentType === "pixel" && (
              <ImageToggle
                imageOn={pixelShieldImageOn}
                imageOff={pixelShieldImageOff}
                iconLeftContent={iconDigitalPrintGray}
                labelContent={"5/identityListItemTitle"}
                descriptionContent={"5/identityListItemDescription"}
                showIconLeft={true}
                borderRadiusSide="left"
              />
            )}
            {componentType === "chat" && <ChatImageAnimation />}
          </div>
        </>
      ) : (
        <>
          <div className="purple-div">
            {componentType === "map" && (
              <ImageToggle
                imageOn={mapShieldImageOn}
                imageOff={mapShieldImageOff}
                labelContent={"5/locationListItemTitle"}
                descriptionContent={"5/locationListItemDescription"}
                showIconLeft={false}
                showModal={true}
                borderRadiusSide="right"
              />
            )}
            {componentType === "pixel" && (
              <ImageToggle
                imageOn={pixelShieldImageOn}
                imageOff={pixelShieldImageOff}
                iconLeftContent={iconDigitalPrintGray}
                labelContent={"5/identityListItemTitle"}
                descriptionContent={"5/identityListItemDescription"}
                showIconLeft={true}
                borderRadiusSide="left"
              />
            )}
            {componentType === "chat" && <ChatImageAnimation />}
          </div>
          <div className="yellow-div">
            <div className="text-container">
              <img className="title-icon margin-bottom-24 padding-4" src={featureIcon} alt="Icon" />
              <h4 textkey={featureTitle} />
              <p textkey={featureSubTitle} />
              <span>by Roses™</span>
              <div className="feature-description padding-vert-24">
                <p textkey={featureDescription} />
              </div>
              {listItemCount > 0 && (
                <ListItem
                  showSphereIcon
                  showLabel
                  labelContent={listItem1LabelContent}
                  showDescription
                  descriptionContent={listItem1DescriptionContent}
                />
              )}
              {listItemCount > 1 && <div className="divider"></div>}
              {listItemCount > 1 && (
                <ListItem
                  showSphereIcon
                  showLabel
                  labelContent={listItem2LabelContent || ""}
                  showDescription
                  descriptionContent={listItem2DescriptionContent || ""}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ContentWrapper;
