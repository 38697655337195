import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import Button from "../../Buttons/Button";

import lupeIconGradient from "../../../assets/icons/icon-lupe-gradient.svg";
import patchUserPreferences from "../../../api/userPreferences/patchUserPreferences";
import { useUserInfo } from "../../../hooks/userInfo";
import ListItemSecondary from "../../Lists/ListItemSecondary";

interface ModalInitialPreferencesProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalInitialPreferences: React.FC<ModalInitialPreferencesProps> = ({
  isOpen = false,
  setIsOpen,
}) => {
  const { userInfo } = useUserInfo();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);

  const [genderConnect, setGenderConnect] = useState([
    { id: 1, label: "Woman", value: false },
    { id: 2, label: "Man", value: false },
    { id: 3, label: "Non-Binary", value: false },
    { id: 4, label: "Trans", value: false },
  ]);

  const handleSkip = () => {
    setGenderConnect((prevState) =>
      prevState.map((gender) => ({ ...gender, value: true }))
    );
  };

  const handleGenderConnect = (id: number) => {
    const updatedCheckboxes = genderConnect.map((gender) =>
      gender.id === id ? { ...gender, value: !gender.value } : gender
    );
    setGenderConnect(updatedCheckboxes);
  };

  const addGenderSubmit = useCallback(async () => {
    setLoading(true);

    const createdUserData = {
      preferedGenders: genderConnect
        .filter((item) => item.value)
        .map((item) =>
          item.label === "Non-Binary" ? "NONBINARY" : item.label.toUpperCase()
        ),
    };

    if (userInfo.access_token) {
      const response = await patchUserPreferences(
        userInfo.access_token,
        createdUserData
      );

      setLoading(false);

      if (response?.status === 200) {
        setIsOpen(false); 
      } else {
        setHasError(true);
        setErrorMessage("Unexpected error");
      }
    }
  }, [genderConnect, userInfo.access_token, setIsOpen]);

  const handleSubmitClick = () => {
    addGenderSubmit();
  };

  useEffect(() => {
    if (genderConnect.every((gender) => gender.value)) {
      addGenderSubmit();
    }
  }, [genderConnect, addGenderSubmit]);

  return (
    <>
      {isOpen && <div className="modal-overlay"></div>}
      <dialog
        id="modal-initial-preferences"
        className="padding-40"
        open={isOpen} 
      >
        <div className="modal-container">
          <div className="modal-icon-wrapper">
            <img src={lupeIconGradient} alt="Lupe Icon" />
            <h6 className="no-margin-top">CHOOSE PREFERENCES</h6>
          </div>
          {genderConnect.map((gender, index) => (
            <ListItemSecondary
              key={index}
              showCheckboxLeft
              showLabel
              labelContent={gender.label}
              checkBoxLeftHandler={() => handleGenderConnect(gender.id)}
              showSeparator={genderConnect.length > index + 1}
            />
          ))}
          <div className="buttons-wrapper">
            <Button
              buttonStyle="primary"
              onClick={handleSubmitClick} 
              children={"Apply Preferences"}
            />
            <Button
              buttonStyle="quaternary"
              onClick={handleSkip}
              children={"Skip"}
            />
          </div>
        </div>
      </dialog>
    </>
  );
};

export default ModalInitialPreferences;
