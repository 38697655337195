import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import InputText from "../../components/Inputs/InputText";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import InfoIcon from "../../assets/icons/union-gray.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useState } from "react";
import SubText from "../../components/Texts/SubText";

const UserSettingsAppLanguagePage: React.FC = () => {
  const [residence, setResidence] = useState("Brazil");
  const [language, setLanguage] = useState("English");

  const { setPrincipalScreen } = useDeskNavigation();

  return (
    <div className="user-settings-language">
      <PageTitle
        title={"Language & Location"}
        subtitle={"Preferences"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/user-settings-account")}
				className="margin-hor-24"
      />
      <div className="margin-hor-24">
        <InputText
          label="Legal Residence"
          iconRight={chevronRightIcon}
          value={residence}
          onChangeInput={() => {}}
          inputType="text"
        />
        <SubText icon={InfoIcon} altIcon="info icon">
          If you want to change your country, please contact{" "}
          <span className="anchor-link">customer support</span>
        </SubText>
        <InputText
          label="Default Language"
          iconRight={chevronRightIcon}
          value={language}
          onChangeInput={() => {}}
          inputType="text"
        />
      </div>
    </div>
  );
};

export default UserSettingsAppLanguagePage;
